import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _propTypes2 from "prop-types";

var _propTypes = "default" in _propTypes2 ? _propTypes2.default : _propTypes2;

import * as _classnames2 from "classnames";

var _classnames = "default" in _classnames2 ? _classnames2.default : _classnames2;

import * as _isDate2 from "date-fns/isDate";

var _isDate = "default" in _isDate2 ? _isDate2.default : _isDate2;

import * as _isValid2 from "date-fns/isValid";

var _isValid = "default" in _isValid2 ? _isValid2.default : _isValid2;

import * as _format2 from "date-fns/format";

var _format = "default" in _format2 ? _format2.default : _format2;

import * as _addMinutes2 from "date-fns/addMinutes";

var _addMinutes = "default" in _addMinutes2 ? _addMinutes2.default : _addMinutes2;

import * as _addHours2 from "date-fns/addHours";

var _addHours = "default" in _addHours2 ? _addHours2.default : _addHours2;

import * as _addDays2 from "date-fns/addDays";

var _addDays = "default" in _addDays2 ? _addDays2.default : _addDays2;

import * as _addWeeks2 from "date-fns/addWeeks";

var _addWeeks = "default" in _addWeeks2 ? _addWeeks2.default : _addWeeks2;

import * as _addMonths2 from "date-fns/addMonths";

var _addMonths = "default" in _addMonths2 ? _addMonths2.default : _addMonths2;

import * as _addYears2 from "date-fns/addYears";

var _addYears = "default" in _addYears2 ? _addYears2.default : _addYears2;

import * as _subMinutes2 from "date-fns/subMinutes";

var _subMinutes = "default" in _subMinutes2 ? _subMinutes2.default : _subMinutes2;

import * as _subHours2 from "date-fns/subHours";

var _subHours = "default" in _subHours2 ? _subHours2.default : _subHours2;

import * as _subDays2 from "date-fns/subDays";

var _subDays = "default" in _subDays2 ? _subDays2.default : _subDays2;

import * as _subWeeks2 from "date-fns/subWeeks";

var _subWeeks = "default" in _subWeeks2 ? _subWeeks2.default : _subWeeks2;

import * as _subMonths2 from "date-fns/subMonths";

var _subMonths = "default" in _subMonths2 ? _subMonths2.default : _subMonths2;

import * as _subYears2 from "date-fns/subYears";

var _subYears = "default" in _subYears2 ? _subYears2.default : _subYears2;

import * as _getSeconds2 from "date-fns/getSeconds";

var _getSeconds = "default" in _getSeconds2 ? _getSeconds2.default : _getSeconds2;

import * as _getMinutes2 from "date-fns/getMinutes";

var _getMinutes = "default" in _getMinutes2 ? _getMinutes2.default : _getMinutes2;

import * as _getHours2 from "date-fns/getHours";

var _getHours = "default" in _getHours2 ? _getHours2.default : _getHours2;

import * as _getDay2 from "date-fns/getDay";

var _getDay = "default" in _getDay2 ? _getDay2.default : _getDay2;

import * as _getDate2 from "date-fns/getDate";

var _getDate = "default" in _getDate2 ? _getDate2.default : _getDate2;

import * as _getISOWeek2 from "date-fns/getISOWeek";

var _getISOWeek = "default" in _getISOWeek2 ? _getISOWeek2.default : _getISOWeek2;

import * as _getMonth2 from "date-fns/getMonth";

var _getMonth = "default" in _getMonth2 ? _getMonth2.default : _getMonth2;

import * as _getQuarter2 from "date-fns/getQuarter";

var _getQuarter = "default" in _getQuarter2 ? _getQuarter2.default : _getQuarter2;

import * as _getYear2 from "date-fns/getYear";

var _getYear = "default" in _getYear2 ? _getYear2.default : _getYear2;

import * as _getTime2 from "date-fns/getTime";

var _getTime = "default" in _getTime2 ? _getTime2.default : _getTime2;

import * as _setSeconds2 from "date-fns/setSeconds";

var _setSeconds = "default" in _setSeconds2 ? _setSeconds2.default : _setSeconds2;

import * as _setMinutes2 from "date-fns/setMinutes";

var _setMinutes = "default" in _setMinutes2 ? _setMinutes2.default : _setMinutes2;

import * as _setHours2 from "date-fns/setHours";

var _setHours = "default" in _setHours2 ? _setHours2.default : _setHours2;

import * as _setMonth2 from "date-fns/setMonth";

var _setMonth = "default" in _setMonth2 ? _setMonth2.default : _setMonth2;

import * as _setQuarter2 from "date-fns/setQuarter";

var _setQuarter = "default" in _setQuarter2 ? _setQuarter2.default : _setQuarter2;

import * as _setYear2 from "date-fns/setYear";

var _setYear = "default" in _setYear2 ? _setYear2.default : _setYear2;

import * as _min2 from "date-fns/min";

var _min = "default" in _min2 ? _min2.default : _min2;

import * as _max2 from "date-fns/max";

var _max = "default" in _max2 ? _max2.default : _max2;

import * as _differenceInCalendarDays2 from "date-fns/differenceInCalendarDays";

var _differenceInCalendarDays = "default" in _differenceInCalendarDays2 ? _differenceInCalendarDays2.default : _differenceInCalendarDays2;

import * as _differenceInCalendarMonths2 from "date-fns/differenceInCalendarMonths";

var _differenceInCalendarMonths = "default" in _differenceInCalendarMonths2 ? _differenceInCalendarMonths2.default : _differenceInCalendarMonths2;

import * as _differenceInCalendarWeeks2 from "date-fns/differenceInCalendarWeeks";

var _differenceInCalendarWeeks = "default" in _differenceInCalendarWeeks2 ? _differenceInCalendarWeeks2.default : _differenceInCalendarWeeks2;

import * as _differenceInCalendarYears2 from "date-fns/differenceInCalendarYears";

var _differenceInCalendarYears = "default" in _differenceInCalendarYears2 ? _differenceInCalendarYears2.default : _differenceInCalendarYears2;

import * as _startOfDay2 from "date-fns/startOfDay";

var _startOfDay = "default" in _startOfDay2 ? _startOfDay2.default : _startOfDay2;

import * as _startOfWeek2 from "date-fns/startOfWeek";

var _startOfWeek = "default" in _startOfWeek2 ? _startOfWeek2.default : _startOfWeek2;

import * as _startOfMonth2 from "date-fns/startOfMonth";

var _startOfMonth = "default" in _startOfMonth2 ? _startOfMonth2.default : _startOfMonth2;

import * as _startOfQuarter2 from "date-fns/startOfQuarter";

var _startOfQuarter = "default" in _startOfQuarter2 ? _startOfQuarter2.default : _startOfQuarter2;

import * as _startOfYear2 from "date-fns/startOfYear";

var _startOfYear = "default" in _startOfYear2 ? _startOfYear2.default : _startOfYear2;

import * as _endOfDay2 from "date-fns/endOfDay";

var _endOfDay = "default" in _endOfDay2 ? _endOfDay2.default : _endOfDay2;

import * as _endOfWeek2 from "date-fns/endOfWeek";

var _endOfWeek = "default" in _endOfWeek2 ? _endOfWeek2.default : _endOfWeek2;

import * as _endOfMonth2 from "date-fns/endOfMonth";

var _endOfMonth = "default" in _endOfMonth2 ? _endOfMonth2.default : _endOfMonth2;

import * as _isEqual2 from "date-fns/isEqual";

var _isEqual = "default" in _isEqual2 ? _isEqual2.default : _isEqual2;

import * as _isSameDay2 from "date-fns/isSameDay";

var _isSameDay = "default" in _isSameDay2 ? _isSameDay2.default : _isSameDay2;

import * as _isSameMonth2 from "date-fns/isSameMonth";

var _isSameMonth = "default" in _isSameMonth2 ? _isSameMonth2.default : _isSameMonth2;

import * as _isSameYear2 from "date-fns/isSameYear";

var _isSameYear = "default" in _isSameYear2 ? _isSameYear2.default : _isSameYear2;

import * as _isSameQuarter2 from "date-fns/isSameQuarter";

var _isSameQuarter = "default" in _isSameQuarter2 ? _isSameQuarter2.default : _isSameQuarter2;

import * as _isAfter2 from "date-fns/isAfter";

var _isAfter = "default" in _isAfter2 ? _isAfter2.default : _isAfter2;

import * as _isBefore2 from "date-fns/isBefore";

var _isBefore = "default" in _isBefore2 ? _isBefore2.default : _isBefore2;

import * as _isWithinInterval2 from "date-fns/isWithinInterval";

var _isWithinInterval = "default" in _isWithinInterval2 ? _isWithinInterval2.default : _isWithinInterval2;

import * as _toDate2 from "date-fns/toDate";

var _toDate = "default" in _toDate2 ? _toDate2.default : _toDate2;

import * as _parse2 from "date-fns/parse";

var _parse = "default" in _parse2 ? _parse2.default : _parse2;

import * as _parseISO2 from "date-fns/parseISO";

var _parseISO = "default" in _parseISO2 ? _parseISO2.default : _parseISO2;

import * as _reactOnclickoutside2 from "react-onclickoutside";

var _reactOnclickoutside = "default" in _reactOnclickoutside2 ? _reactOnclickoutside2.default : _reactOnclickoutside2;

import * as _reactDom2 from "react-dom";

var _reactDom = "default" in _reactDom2 ? _reactDom2.default : _reactDom2;

import * as _reactPopper2 from "react-popper";

var _reactPopper = "default" in _reactPopper2 ? _reactPopper2.default : _reactPopper2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  t(exports, _react, _propTypes, _classnames, _isDate, _isValid, _format, _addMinutes, _addHours, _addDays, _addWeeks, _addMonths, _addYears, _subMinutes, _subHours, _subDays, _subWeeks, _subMonths, _subYears, _getSeconds, _getMinutes, _getHours, _getDay, _getDate, _getISOWeek, _getMonth, _getQuarter, _getYear, _getTime, _setSeconds, _setMinutes, _setHours, _setMonth, _setQuarter, _setYear, _min, _max, _differenceInCalendarDays, _differenceInCalendarMonths, _differenceInCalendarWeeks, _differenceInCalendarYears, _startOfDay, _startOfWeek, _startOfMonth, _startOfQuarter, _startOfYear, _endOfDay, _endOfWeek, _endOfMonth, _isEqual, _isSameDay, _isSameMonth, _isSameYear, _isSameQuarter, _isAfter, _isBefore, _isWithinInterval, _toDate, _parse, _parseISO, _reactOnclickoutside, _reactDom, _reactPopper);
}(exports, function (e, t, r, a, n, o, s, i, p, l, d, c, u, f, h, m, y, v, D, w, g, k, b, S, C, _, M, P, E, N, O, Y, x, I, T, L, R, F, A, q, W, B, K, H, j, Q, V, U, $, z, G, J, X, Z, ee, te, re, ae, ne, oe, se, ie, pe) {
  "use strict";

  function le(e) {
    return e && "object" == typeof e && "default" in e ? e : {
      default: e
    };
  }

  var de = le(t),
      ce = le(a),
      ue = le(n),
      fe = le(o),
      he = le(s),
      me = le(i),
      ye = le(p),
      ve = le(l),
      De = le(d),
      we = le(c),
      ge = le(u),
      ke = le(m),
      be = le(y),
      Se = le(v),
      Ce = le(D),
      _e = le(w),
      Me = le(g),
      Pe = le(k),
      Ee = le(b),
      Ne = le(S),
      Oe = le(C),
      Ye = le(_),
      xe = le(M),
      Ie = le(P),
      Te = le(E),
      Le = le(N),
      Re = le(O),
      Fe = le(Y),
      Ae = le(x),
      qe = le(I),
      We = le(T),
      Be = le(L),
      Ke = le(R),
      He = le(F),
      je = le(A),
      Qe = le(W),
      Ve = le(B),
      Ue = le(K),
      $e = le(H),
      ze = le(j),
      Ge = le(Q),
      Je = le(V),
      Xe = le(z),
      Ze = le(G),
      et = le(J),
      tt = le(X),
      rt = le(Z),
      at = le(ee),
      nt = le(te),
      ot = le(re),
      st = le(ae),
      it = le(ne),
      pt = le(oe),
      lt = le(se),
      dt = le(ie);

  function ct(e, t) {
    var r = Object.keys(e);

    if (Object.getOwnPropertySymbols) {
      var a = Object.getOwnPropertySymbols(e);
      t && (a = a.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })), r.push.apply(r, a);
    }

    return r;
  }

  function ut(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = null != arguments[t] ? arguments[t] : {};
      t % 2 ? ct(Object(r), !0).forEach(function (t) {
        vt(e, t, r[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(r)) : ct(Object(r)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t));
      });
    }

    return e;
  }

  function ft(e) {
    return (ft = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    })(e);
  }

  function ht(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
  }

  function mt(e, t) {
    for (var r = 0; r < t.length; r++) {
      var a = t[r];
      a.enumerable = a.enumerable || !1, a.configurable = !0, "value" in a && (a.writable = !0), Object.defineProperty(e, a.key, a);
    }
  }

  function yt(e, t, r) {
    return t && mt(e.prototype, t), r && mt(e, r), Object.defineProperty(e, "prototype", {
      writable: !1
    }), e;
  }

  function vt(e, t, r) {
    return t in e ? Object.defineProperty(e, t, {
      value: r,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = r, e;
  }

  function Dt() {
    return (Dt = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var a in r) Object.prototype.hasOwnProperty.call(r, a) && (e[a] = r[a]);
      }

      return e;
    }).apply(this || _global, arguments);
  }

  function wt(e, t) {
    if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
    Object.defineProperty(e, "prototype", {
      value: Object.create(t && t.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }),
      writable: !1
    }), t && kt(e, t);
  }

  function gt(e) {
    return (gt = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }

  function kt(e, t) {
    return (kt = Object.setPrototypeOf || function (e, t) {
      return e.__proto__ = t, e;
    })(e, t);
  }

  function bt(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }

  function St(e, t) {
    if (t && ("object" == typeof t || "function" == typeof t)) return t;
    if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
    return bt(e);
  }

  function Ct(e) {
    var t = function () {
      if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
      if (Reflect.construct.sham) return !1;
      if ("function" == typeof Proxy) return !0;

      try {
        return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
      } catch (e) {
        return !1;
      }
    }();

    return function () {
      var r,
          a = gt(e);

      if (t) {
        var n = gt(this || _global).constructor;
        r = Reflect.construct(a, arguments, n);
      } else r = a.apply(this || _global, arguments);

      return St(this || _global, r);
    };
  }

  function _t(e) {
    return function (e) {
      if (Array.isArray(e)) return Mt(e);
    }(e) || function (e) {
      if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
    }(e) || function (e, t) {
      if (!e) return;
      if ("string" == typeof e) return Mt(e, t);
      var r = Object.prototype.toString.call(e).slice(8, -1);
      "Object" === r && e.constructor && (r = e.constructor.name);
      if ("Map" === r || "Set" === r) return Array.from(e);
      if ("Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)) return Mt(e, t);
    }(e) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }();
  }

  function Mt(e, t) {
    (null == t || t > e.length) && (t = e.length);

    for (var r = 0, a = new Array(t); r < t; r++) a[r] = e[r];

    return a;
  }

  function Pt(e, t) {
    switch (e) {
      case "P":
        return t.date({
          width: "short"
        });

      case "PP":
        return t.date({
          width: "medium"
        });

      case "PPP":
        return t.date({
          width: "long"
        });

      case "PPPP":
      default:
        return t.date({
          width: "full"
        });
    }
  }

  function Et(e, t) {
    switch (e) {
      case "p":
        return t.time({
          width: "short"
        });

      case "pp":
        return t.time({
          width: "medium"
        });

      case "ppp":
        return t.time({
          width: "long"
        });

      case "pppp":
      default:
        return t.time({
          width: "full"
        });
    }
  }

  var Nt = {
    p: Et,
    P: function (e, t) {
      var r,
          a = e.match(/(P+)(p+)?/) || [],
          n = a[1],
          o = a[2];
      if (!o) return Pt(e, t);

      switch (n) {
        case "P":
          r = t.dateTime({
            width: "short"
          });
          break;

        case "PP":
          r = t.dateTime({
            width: "medium"
          });
          break;

        case "PPP":
          r = t.dateTime({
            width: "long"
          });
          break;

        case "PPPP":
        default:
          r = t.dateTime({
            width: "full"
          });
      }

      return r.replace("{{date}}", Pt(n, t)).replace("{{time}}", Et(o, t));
    }
  },
      Ot = 12,
      Yt = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;

  function xt(e) {
    var t = e ? "string" == typeof e || e instanceof String ? pt.default(e) : st.default(e) : new Date();
    return Tt(t) ? t : null;
  }

  function It(e, t, r, a, n) {
    var o = null,
        s = Xt(r) || Xt(Jt()),
        i = !0;
    return Array.isArray(t) ? (t.forEach(function (t) {
      var r = it.default(e, t, new Date(), {
        locale: s
      });
      a && (i = Tt(r, n) && e === he.default(r, t, {
        awareOfUnicodeTokens: !0
      })), Tt(r, n) && i && (o = r);
    }), o) : (o = it.default(e, t, new Date(), {
      locale: s
    }), a ? i = Tt(o) && e === he.default(o, t, {
      awareOfUnicodeTokens: !0
    }) : Tt(o) || (t = t.match(Yt).map(function (e) {
      var t = e[0];
      return "p" === t || "P" === t ? s ? (0, Nt[t])(e, s.formatLong) : t : e;
    }).join(""), e.length > 0 && (o = it.default(e, t.slice(0, e.length), new Date())), Tt(o) || (o = new Date(e))), Tt(o) && i ? o : null);
  }

  function Tt(e, t) {
    return t = t || new Date("1/1/1000"), fe.default(e) && !nt.default(e, t);
  }

  function Lt(e, t, r) {
    if ("en" === r) return he.default(e, t, {
      awareOfUnicodeTokens: !0
    });
    var a = Xt(r);
    return r && !a && console.warn("A locale object was not found for the provided string [\"".concat(r, "\"].")), !a && Jt() && Xt(Jt()) && (a = Xt(Jt())), he.default(e, t, {
      locale: a || null,
      awareOfUnicodeTokens: !0
    });
  }

  function Rt(e, t) {
    var r = t.dateFormat,
        a = t.locale;
    return e && Lt(e, Array.isArray(r) ? r[0] : r, a) || "";
  }

  function Ft(e, t) {
    var r = t.hour,
        a = void 0 === r ? 0 : r,
        n = t.minute,
        o = void 0 === n ? 0 : n,
        s = t.second,
        i = void 0 === s ? 0 : s;
    return Fe.default(Re.default(Le.default(e, i), o), a);
  }

  function At(e, t) {
    var r = t && Xt(t) || Jt() && Xt(Jt());
    return Oe.default(e, r ? {
      locale: r
    } : null);
  }

  function qt(e, t) {
    return Lt(e, "ddd", t);
  }

  function Wt(e) {
    return Ve.default(e);
  }

  function Bt(e, t, r) {
    var a = Xt(t || Jt());
    return Ue.default(e, {
      locale: a,
      weekStartsOn: r
    });
  }

  function Kt(e) {
    return $e.default(e);
  }

  function Ht(e) {
    return Ge.default(e);
  }

  function jt(e) {
    return ze.default(e);
  }

  function Qt(e, t) {
    return e && t ? tt.default(e, t) : !e && !t;
  }

  function Vt(e, t) {
    return e && t ? et.default(e, t) : !e && !t;
  }

  function Ut(e, t) {
    return e && t ? rt.default(e, t) : !e && !t;
  }

  function $t(e, t) {
    return e && t ? Ze.default(e, t) : !e && !t;
  }

  function zt(e, t) {
    return e && t ? Xe.default(e, t) : !e && !t;
  }

  function Gt(e, t, r) {
    var a,
        n = Ve.default(t),
        o = Je.default(r);

    try {
      a = ot.default(e, {
        start: n,
        end: o
      });
    } catch (e) {
      a = !1;
    }

    return a;
  }

  function Jt() {
    return ("undefined" != typeof window ? window : _global).__localeId__;
  }

  function Xt(e) {
    if ("string" == typeof e) {
      var t = "undefined" != typeof window ? window : _global;
      return t.__localeData__ ? t.__localeData__[e] : null;
    }

    return e;
  }

  function Zt(e, t) {
    return Lt(Ae.default(xt(), e), "LLLL", t);
  }

  function er(e, t) {
    return Lt(Ae.default(xt(), e), "LLL", t);
  }

  function tr(e, t) {
    return Lt(qe.default(xt(), e), "QQQ", t);
  }

  function rr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.maxDate,
        n = t.excludeDates,
        o = t.excludeDateIntervals,
        s = t.includeDates,
        i = t.includeDateIntervals,
        p = t.filterDate;
    return lr(e, {
      minDate: r,
      maxDate: a
    }) || n && n.some(function (t) {
      return $t(e, t);
    }) || o && o.some(function (t) {
      var r = t.start,
          a = t.end;
      return ot.default(e, {
        start: r,
        end: a
      });
    }) || s && !s.some(function (t) {
      return $t(e, t);
    }) || i && !i.some(function (t) {
      var r = t.start,
          a = t.end;
      return ot.default(e, {
        start: r,
        end: a
      });
    }) || p && !p(xt(e)) || !1;
  }

  function ar(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.excludeDates,
        a = t.excludeDateIntervals;
    return a && a.length > 0 ? a.some(function (t) {
      var r = t.start,
          a = t.end;
      return ot.default(e, {
        start: r,
        end: a
      });
    }) : r && r.some(function (t) {
      return $t(e, t);
    }) || !1;
  }

  function nr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.maxDate,
        n = t.excludeDates,
        o = t.includeDates,
        s = t.filterDate;
    return lr(e, {
      minDate: r,
      maxDate: a
    }) || n && n.some(function (t) {
      return Vt(e, t);
    }) || o && !o.some(function (t) {
      return Vt(e, t);
    }) || s && !s(xt(e)) || !1;
  }

  function or(e, t, r, a) {
    var n = Ie.default(e),
        o = Ye.default(e),
        s = Ie.default(t),
        i = Ye.default(t),
        p = Ie.default(a);
    return n === s && n === p ? o <= r && r <= i : n < s ? p === n && o <= r || p === s && i >= r || p < s && p > n : void 0;
  }

  function sr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.maxDate,
        n = t.excludeDates,
        o = t.includeDates,
        s = t.filterDate;
    return lr(e, {
      minDate: r,
      maxDate: a
    }) || n && n.some(function (t) {
      return Ut(e, t);
    }) || o && !o.some(function (t) {
      return Ut(e, t);
    }) || s && !s(xt(e)) || !1;
  }

  function ir(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.maxDate,
        n = new Date(e, 0, 1);
    return lr(n, {
      minDate: r,
      maxDate: a
    }) || !1;
  }

  function pr(e, t, r, a) {
    var n = Ie.default(e),
        o = xe.default(e),
        s = Ie.default(t),
        i = xe.default(t),
        p = Ie.default(a);
    return n === s && n === p ? o <= r && r <= i : n < s ? p === n && o <= r || p === s && i >= r || p < s && p > n : void 0;
  }

  function lr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.maxDate;
    return r && He.default(e, r) < 0 || a && He.default(e, a) > 0;
  }

  function dr(e, t) {
    return t.some(function (t) {
      return Pe.default(t) === Pe.default(e) && Me.default(t) === Me.default(e);
    });
  }

  function cr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.excludeTimes,
        a = t.includeTimes,
        n = t.filterTime;
    return r && dr(e, r) || a && !dr(e, a) || n && !n(e) || !1;
  }

  function ur(e, t) {
    var r = t.minTime,
        a = t.maxTime;
    if (!r || !a) throw new Error("Both minTime and maxTime props required");
    var n,
        o = xt(),
        s = Fe.default(Re.default(o, Me.default(e)), Pe.default(e)),
        i = Fe.default(Re.default(o, Me.default(r)), Pe.default(r)),
        p = Fe.default(Re.default(o, Me.default(a)), Pe.default(a));

    try {
      n = !ot.default(s, {
        start: i,
        end: p
      });
    } catch (e) {
      n = !1;
    }

    return n;
  }

  function fr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.includeDates,
        n = Se.default(e, 1);
    return r && je.default(r, n) > 0 || a && a.every(function (e) {
      return je.default(e, n) > 0;
    }) || !1;
  }

  function hr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.maxDate,
        a = t.includeDates,
        n = we.default(e, 1);
    return r && je.default(n, r) > 0 || a && a.every(function (e) {
      return je.default(n, e) > 0;
    }) || !1;
  }

  function mr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.minDate,
        a = t.includeDates,
        n = Ce.default(e, 1);
    return r && Qe.default(r, n) > 0 || a && a.every(function (e) {
      return Qe.default(e, n) > 0;
    }) || !1;
  }

  function yr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        r = t.maxDate,
        a = t.includeDates,
        n = ge.default(e, 1);
    return r && Qe.default(n, r) > 0 || a && a.every(function (e) {
      return Qe.default(n, e) > 0;
    }) || !1;
  }

  function vr(e) {
    var t = e.minDate,
        r = e.includeDates;

    if (r && t) {
      var a = r.filter(function (e) {
        return He.default(e, t) >= 0;
      });
      return Be.default(a);
    }

    return r ? Be.default(r) : t;
  }

  function Dr(e) {
    var t = e.maxDate,
        r = e.includeDates;

    if (r && t) {
      var a = r.filter(function (e) {
        return He.default(e, t) <= 0;
      });
      return Ke.default(a);
    }

    return r ? Ke.default(r) : t;
  }

  function wr() {
    for (var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [], t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "react-datepicker__day--highlighted", r = new Map(), a = 0, n = e.length; a < n; a++) {
      var o = e[a];

      if (ue.default(o)) {
        var s = Lt(o, "MM.dd.yyyy"),
            i = r.get(s) || [];
        i.includes(t) || (i.push(t), r.set(s, i));
      } else if ("object" === ft(o)) {
        var p = Object.keys(o),
            l = p[0],
            d = o[p[0]];
        if ("string" == typeof l && d.constructor === Array) for (var c = 0, u = d.length; c < u; c++) {
          var f = Lt(d[c], "MM.dd.yyyy"),
              h = r.get(f) || [];
          h.includes(l) || (h.push(l), r.set(f, h));
        }
      }
    }

    return r;
  }

  function gr(e, t, r, a, n) {
    for (var o = n.length, s = [], i = 0; i < o; i++) {
      var p = me.default(ye.default(e, Pe.default(n[i])), Me.default(n[i])),
          l = me.default(e, (r + 1) * a);
      at.default(p, t) && nt.default(p, l) && s.push(n[i]);
    }

    return s;
  }

  function kr(e) {
    return e < 10 ? "0".concat(e) : "".concat(e);
  }

  function br(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : Ot,
        r = Math.ceil(Ie.default(e) / t) * t,
        a = r - (t - 1);
    return {
      startPeriod: a,
      endPeriod: r
    };
  }

  function Sr(e, t, r, a) {
    for (var n = [], o = 0; o < 2 * t + 1; o++) {
      var s = e + t - o,
          i = !0;
      r && (i = Ie.default(r) <= s), a && i && (i = Ie.default(a) >= s), i && n.push(s);
    }

    return n;
  }

  var Cr = function (e) {
    wt(a, e);
    var r = Ct(a);

    function a(e) {
      var n;
      ht(this || _global, a), vt(bt(n = r.call(this || _global, e)), "renderOptions", function () {
        var e = n.props.year,
            t = n.state.yearsList.map(function (t) {
          return de.default.createElement("div", {
            className: e === t ? "react-datepicker__year-option react-datepicker__year-option--selected_year" : "react-datepicker__year-option",
            key: t,
            onClick: n.onChange.bind(bt(n), t),
            "aria-selected": e === t ? "true" : void 0
          }, e === t ? de.default.createElement("span", {
            className: "react-datepicker__year-option--selected"
          }, "\u2713") : "", t);
        }),
            r = n.props.minDate ? Ie.default(n.props.minDate) : null,
            a = n.props.maxDate ? Ie.default(n.props.maxDate) : null;
        return a && n.state.yearsList.find(function (e) {
          return e === a;
        }) || t.unshift(de.default.createElement("div", {
          className: "react-datepicker__year-option",
          key: "upcoming",
          onClick: n.incrementYears
        }, de.default.createElement("a", {
          className: "react-datepicker__navigation react-datepicker__navigation--years react-datepicker__navigation--years-upcoming"
        }))), r && n.state.yearsList.find(function (e) {
          return e === r;
        }) || t.push(de.default.createElement("div", {
          className: "react-datepicker__year-option",
          key: "previous",
          onClick: n.decrementYears
        }, de.default.createElement("a", {
          className: "react-datepicker__navigation react-datepicker__navigation--years react-datepicker__navigation--years-previous"
        }))), t;
      }), vt(bt(n), "onChange", function (e) {
        n.props.onChange(e);
      }), vt(bt(n), "handleClickOutside", function () {
        n.props.onCancel();
      }), vt(bt(n), "shiftYears", function (e) {
        var t = n.state.yearsList.map(function (t) {
          return t + e;
        });
        n.setState({
          yearsList: t
        });
      }), vt(bt(n), "incrementYears", function () {
        return n.shiftYears(1);
      }), vt(bt(n), "decrementYears", function () {
        return n.shiftYears(-1);
      });
      var o = e.yearDropdownItemNumber,
          s = e.scrollableYearDropdown,
          i = o || (s ? 10 : 5);
      return n.state = {
        yearsList: Sr(n.props.year, i, n.props.minDate, n.props.maxDate)
      }, n.dropdownRef = t.createRef(), n;
    }

    return yt(a, [{
      key: "componentDidMount",
      value: function () {
        var e = (this || _global).dropdownRef.current;
        e && (e.scrollTop = e.scrollHeight / 2 - e.clientHeight / 2);
      }
    }, {
      key: "render",
      value: function () {
        var e = ce.default({
          "react-datepicker__year-dropdown": !0,
          "react-datepicker__year-dropdown--scrollable": (this || _global).props.scrollableYearDropdown
        });
        return de.default.createElement("div", {
          className: e,
          ref: (this || _global).dropdownRef
        }, this.renderOptions());
      }
    }]), a;
  }(de.default.Component),
      _r = lt.default(Cr),
      Mr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "state", {
        dropdownVisible: !1
      }), vt(bt(e), "renderSelectOptions", function () {
        for (var t = e.props.minDate ? Ie.default(e.props.minDate) : 1900, r = e.props.maxDate ? Ie.default(e.props.maxDate) : 2100, a = [], n = t; n <= r; n++) a.push(de.default.createElement("option", {
          key: n,
          value: n
        }, n));

        return a;
      }), vt(bt(e), "onSelectChange", function (t) {
        e.onChange(t.target.value);
      }), vt(bt(e), "renderSelectMode", function () {
        return de.default.createElement("select", {
          value: e.props.year,
          className: "react-datepicker__year-select",
          onChange: e.onSelectChange
        }, e.renderSelectOptions());
      }), vt(bt(e), "renderReadView", function (t) {
        return de.default.createElement("div", {
          key: "read",
          style: {
            visibility: t ? "visible" : "hidden"
          },
          className: "react-datepicker__year-read-view",
          onClick: function (t) {
            return e.toggleDropdown(t);
          }
        }, de.default.createElement("span", {
          className: "react-datepicker__year-read-view--down-arrow"
        }), de.default.createElement("span", {
          className: "react-datepicker__year-read-view--selected-year"
        }, e.props.year));
      }), vt(bt(e), "renderDropdown", function () {
        return de.default.createElement(_r, {
          key: "dropdown",
          year: e.props.year,
          onChange: e.onChange,
          onCancel: e.toggleDropdown,
          minDate: e.props.minDate,
          maxDate: e.props.maxDate,
          scrollableYearDropdown: e.props.scrollableYearDropdown,
          yearDropdownItemNumber: e.props.yearDropdownItemNumber
        });
      }), vt(bt(e), "renderScrollMode", function () {
        var t = e.state.dropdownVisible,
            r = [e.renderReadView(!t)];
        return t && r.unshift(e.renderDropdown()), r;
      }), vt(bt(e), "onChange", function (t) {
        e.toggleDropdown(), t !== e.props.year && e.props.onChange(t);
      }), vt(bt(e), "toggleDropdown", function (t) {
        e.setState({
          dropdownVisible: !e.state.dropdownVisible
        }, function () {
          e.props.adjustDateOnChange && e.handleYearChange(e.props.date, t);
        });
      }), vt(bt(e), "handleYearChange", function (t, r) {
        e.onSelect(t, r), e.setOpen();
      }), vt(bt(e), "onSelect", function (t, r) {
        e.props.onSelect && e.props.onSelect(t, r);
      }), vt(bt(e), "setOpen", function () {
        e.props.setOpen && e.props.setOpen(!0);
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e;

        switch ((this || _global).props.dropdownMode) {
          case "scroll":
            e = this.renderScrollMode();
            break;

          case "select":
            e = this.renderSelectMode();
        }

        return de.default.createElement("div", {
          className: "react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--".concat((this || _global).props.dropdownMode)
        }, e);
      }
    }]), r;
  }(de.default.Component),
      Pr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "isSelectedMonth", function (t) {
        return e.props.month === t;
      }), vt(bt(e), "renderOptions", function () {
        return e.props.monthNames.map(function (t, r) {
          return de.default.createElement("div", {
            className: e.isSelectedMonth(r) ? "react-datepicker__month-option react-datepicker__month-option--selected_month" : "react-datepicker__month-option",
            key: t,
            onClick: e.onChange.bind(bt(e), r),
            "aria-selected": e.isSelectedMonth(r) ? "true" : void 0
          }, e.isSelectedMonth(r) ? de.default.createElement("span", {
            className: "react-datepicker__month-option--selected"
          }, "\u2713") : "", t);
        });
      }), vt(bt(e), "onChange", function (t) {
        return e.props.onChange(t);
      }), vt(bt(e), "handleClickOutside", function () {
        return e.props.onCancel();
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        return de.default.createElement("div", {
          className: "react-datepicker__month-dropdown"
        }, this.renderOptions());
      }
    }]), r;
  }(de.default.Component),
      Er = lt.default(Pr),
      Nr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "state", {
        dropdownVisible: !1
      }), vt(bt(e), "renderSelectOptions", function (e) {
        return e.map(function (e, t) {
          return de.default.createElement("option", {
            key: t,
            value: t
          }, e);
        });
      }), vt(bt(e), "renderSelectMode", function (t) {
        return de.default.createElement("select", {
          value: e.props.month,
          className: "react-datepicker__month-select",
          onChange: function (t) {
            return e.onChange(t.target.value);
          }
        }, e.renderSelectOptions(t));
      }), vt(bt(e), "renderReadView", function (t, r) {
        return de.default.createElement("div", {
          key: "read",
          style: {
            visibility: t ? "visible" : "hidden"
          },
          className: "react-datepicker__month-read-view",
          onClick: e.toggleDropdown
        }, de.default.createElement("span", {
          className: "react-datepicker__month-read-view--down-arrow"
        }), de.default.createElement("span", {
          className: "react-datepicker__month-read-view--selected-month"
        }, r[e.props.month]));
      }), vt(bt(e), "renderDropdown", function (t) {
        return de.default.createElement(Er, {
          key: "dropdown",
          month: e.props.month,
          monthNames: t,
          onChange: e.onChange,
          onCancel: e.toggleDropdown
        });
      }), vt(bt(e), "renderScrollMode", function (t) {
        var r = e.state.dropdownVisible,
            a = [e.renderReadView(!r, t)];
        return r && a.unshift(e.renderDropdown(t)), a;
      }), vt(bt(e), "onChange", function (t) {
        e.toggleDropdown(), t !== e.props.month && e.props.onChange(t);
      }), vt(bt(e), "toggleDropdown", function () {
        return e.setState({
          dropdownVisible: !e.state.dropdownVisible
        });
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e,
            t = this || _global,
            r = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((this || _global).props.useShortMonthInDropdown ? function (e) {
          return er(e, t.props.locale);
        } : function (e) {
          return Zt(e, t.props.locale);
        });

        switch ((this || _global).props.dropdownMode) {
          case "scroll":
            e = this.renderScrollMode(r);
            break;

          case "select":
            e = this.renderSelectMode(r);
        }

        return de.default.createElement("div", {
          className: "react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--".concat((this || _global).props.dropdownMode)
        }, e);
      }
    }]), r;
  }(de.default.Component);

  function Or(e, t) {
    for (var r = [], a = Kt(e), n = Kt(t); !at.default(a, n);) r.push(xt(a)), a = we.default(a, 1);

    return r;
  }

  var Yr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), vt(bt(a = t.call(this || _global, e)), "renderOptions", function () {
        return a.state.monthYearsList.map(function (e) {
          var t = Te.default(e),
              r = Qt(a.props.date, e) && Vt(a.props.date, e);
          return de.default.createElement("div", {
            className: r ? "react-datepicker__month-year-option--selected_month-year" : "react-datepicker__month-year-option",
            key: t,
            onClick: a.onChange.bind(bt(a), t),
            "aria-selected": r ? "true" : void 0
          }, r ? de.default.createElement("span", {
            className: "react-datepicker__month-year-option--selected"
          }, "\u2713") : "", Lt(e, a.props.dateFormat, a.props.locale));
        });
      }), vt(bt(a), "onChange", function (e) {
        return a.props.onChange(e);
      }), vt(bt(a), "handleClickOutside", function () {
        a.props.onCancel();
      }), a.state = {
        monthYearsList: Or(a.props.minDate, a.props.maxDate)
      }, a;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e = ce.default({
          "react-datepicker__month-year-dropdown": !0,
          "react-datepicker__month-year-dropdown--scrollable": (this || _global).props.scrollableMonthYearDropdown
        });
        return de.default.createElement("div", {
          className: e
        }, this.renderOptions());
      }
    }]), r;
  }(de.default.Component),
      xr = lt.default(Yr),
      Ir = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "state", {
        dropdownVisible: !1
      }), vt(bt(e), "renderSelectOptions", function () {
        for (var t = Kt(e.props.minDate), r = Kt(e.props.maxDate), a = []; !at.default(t, r);) {
          var n = Te.default(t);
          a.push(de.default.createElement("option", {
            key: n,
            value: n
          }, Lt(t, e.props.dateFormat, e.props.locale))), t = we.default(t, 1);
        }

        return a;
      }), vt(bt(e), "onSelectChange", function (t) {
        e.onChange(t.target.value);
      }), vt(bt(e), "renderSelectMode", function () {
        return de.default.createElement("select", {
          value: Te.default(Kt(e.props.date)),
          className: "react-datepicker__month-year-select",
          onChange: e.onSelectChange
        }, e.renderSelectOptions());
      }), vt(bt(e), "renderReadView", function (t) {
        var r = Lt(e.props.date, e.props.dateFormat, e.props.locale);
        return de.default.createElement("div", {
          key: "read",
          style: {
            visibility: t ? "visible" : "hidden"
          },
          className: "react-datepicker__month-year-read-view",
          onClick: function (t) {
            return e.toggleDropdown(t);
          }
        }, de.default.createElement("span", {
          className: "react-datepicker__month-year-read-view--down-arrow"
        }), de.default.createElement("span", {
          className: "react-datepicker__month-year-read-view--selected-month-year"
        }, r));
      }), vt(bt(e), "renderDropdown", function () {
        return de.default.createElement(xr, {
          key: "dropdown",
          date: e.props.date,
          dateFormat: e.props.dateFormat,
          onChange: e.onChange,
          onCancel: e.toggleDropdown,
          minDate: e.props.minDate,
          maxDate: e.props.maxDate,
          scrollableMonthYearDropdown: e.props.scrollableMonthYearDropdown,
          locale: e.props.locale
        });
      }), vt(bt(e), "renderScrollMode", function () {
        var t = e.state.dropdownVisible,
            r = [e.renderReadView(!t)];
        return t && r.unshift(e.renderDropdown()), r;
      }), vt(bt(e), "onChange", function (t) {
        e.toggleDropdown();
        var r = xt(parseInt(t));
        Qt(e.props.date, r) && Vt(e.props.date, r) || e.props.onChange(r);
      }), vt(bt(e), "toggleDropdown", function () {
        return e.setState({
          dropdownVisible: !e.state.dropdownVisible
        });
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e;

        switch ((this || _global).props.dropdownMode) {
          case "scroll":
            e = this.renderScrollMode();
            break;

          case "select":
            e = this.renderSelectMode();
        }

        return de.default.createElement("div", {
          className: "react-datepicker__month-year-dropdown-container react-datepicker__month-year-dropdown-container--".concat((this || _global).props.dropdownMode)
        }, e);
      }
    }]), r;
  }(de.default.Component),
      Tr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "dayEl", de.default.createRef()), vt(bt(e), "handleClick", function (t) {
        !e.isDisabled() && e.props.onClick && e.props.onClick(t);
      }), vt(bt(e), "handleMouseEnter", function (t) {
        !e.isDisabled() && e.props.onMouseEnter && e.props.onMouseEnter(t);
      }), vt(bt(e), "handleOnKeyDown", function (t) {
        " " === t.key && (t.preventDefault(), t.key = "Enter"), e.props.handleOnKeyDown(t);
      }), vt(bt(e), "isSameDay", function (t) {
        return $t(e.props.day, t);
      }), vt(bt(e), "isKeyboardSelected", function () {
        return !e.props.disabledKeyboardNavigation && !e.isSameDay(e.props.selected) && e.isSameDay(e.props.preSelection);
      }), vt(bt(e), "isDisabled", function () {
        return rr(e.props.day, e.props);
      }), vt(bt(e), "isExcluded", function () {
        return ar(e.props.day, e.props);
      }), vt(bt(e), "getHighLightedClass", function (t) {
        var r = e.props,
            a = r.day,
            n = r.highlightDates;
        if (!n) return !1;
        var o = Lt(a, "MM.dd.yyyy");
        return n.get(o);
      }), vt(bt(e), "isInRange", function () {
        var t = e.props,
            r = t.day,
            a = t.startDate,
            n = t.endDate;
        return !(!a || !n) && Gt(r, a, n);
      }), vt(bt(e), "isInSelectingRange", function () {
        var t,
            r = e.props,
            a = r.day,
            n = r.selectsStart,
            o = r.selectsEnd,
            s = r.selectsRange,
            i = r.selectsDisabledDaysInRange,
            p = r.startDate,
            l = r.endDate,
            d = null !== (t = e.props.selectingDate) && void 0 !== t ? t : e.props.preSelection;
        return !(!(n || o || s) || !d || !i && e.isDisabled()) && (n && l && (nt.default(d, l) || zt(d, l)) ? Gt(a, d, l) : (o && p && (at.default(d, p) || zt(d, p)) || !(!s || !p || l || !at.default(d, p) && !zt(d, p))) && Gt(a, p, d));
      }), vt(bt(e), "isSelectingRangeStart", function () {
        var t;
        if (!e.isInSelectingRange()) return !1;
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.selectsStart,
            s = null !== (t = e.props.selectingDate) && void 0 !== t ? t : e.props.preSelection;
        return $t(a, o ? s : n);
      }), vt(bt(e), "isSelectingRangeEnd", function () {
        var t;
        if (!e.isInSelectingRange()) return !1;
        var r = e.props,
            a = r.day,
            n = r.endDate,
            o = r.selectsEnd,
            s = null !== (t = e.props.selectingDate) && void 0 !== t ? t : e.props.preSelection;
        return $t(a, o ? s : n);
      }), vt(bt(e), "isRangeStart", function () {
        var t = e.props,
            r = t.day,
            a = t.startDate,
            n = t.endDate;
        return !(!a || !n) && $t(a, r);
      }), vt(bt(e), "isRangeEnd", function () {
        var t = e.props,
            r = t.day,
            a = t.startDate,
            n = t.endDate;
        return !(!a || !n) && $t(n, r);
      }), vt(bt(e), "isWeekend", function () {
        var t = Ee.default(e.props.day);
        return 0 === t || 6 === t;
      }), vt(bt(e), "isAfterMonth", function () {
        return void 0 !== e.props.month && (e.props.month + 1) % 12 === Ye.default(e.props.day);
      }), vt(bt(e), "isBeforeMonth", function () {
        return void 0 !== e.props.month && (Ye.default(e.props.day) + 1) % 12 === e.props.month;
      }), vt(bt(e), "isCurrentDay", function () {
        return e.isSameDay(xt());
      }), vt(bt(e), "isSelected", function () {
        return e.isSameDay(e.props.selected);
      }), vt(bt(e), "getClassNames", function (t) {
        var r = e.props.dayClassName ? e.props.dayClassName(t) : void 0;
        return ce.default("react-datepicker__day", r, "react-datepicker__day--" + qt(e.props.day), {
          "react-datepicker__day--disabled": e.isDisabled(),
          "react-datepicker__day--excluded": e.isExcluded(),
          "react-datepicker__day--selected": e.isSelected(),
          "react-datepicker__day--keyboard-selected": e.isKeyboardSelected(),
          "react-datepicker__day--range-start": e.isRangeStart(),
          "react-datepicker__day--range-end": e.isRangeEnd(),
          "react-datepicker__day--in-range": e.isInRange(),
          "react-datepicker__day--in-selecting-range": e.isInSelectingRange(),
          "react-datepicker__day--selecting-range-start": e.isSelectingRangeStart(),
          "react-datepicker__day--selecting-range-end": e.isSelectingRangeEnd(),
          "react-datepicker__day--today": e.isCurrentDay(),
          "react-datepicker__day--weekend": e.isWeekend(),
          "react-datepicker__day--outside-month": e.isAfterMonth() || e.isBeforeMonth()
        }, e.getHighLightedClass("react-datepicker__day--highlighted"));
      }), vt(bt(e), "getAriaLabel", function () {
        var t = e.props,
            r = t.day,
            a = t.ariaLabelPrefixWhenEnabled,
            n = void 0 === a ? "Choose" : a,
            o = t.ariaLabelPrefixWhenDisabled,
            s = void 0 === o ? "Not available" : o,
            i = e.isDisabled() || e.isExcluded() ? s : n;
        return "".concat(i, " ").concat(Lt(r, "PPPP", e.props.locale));
      }), vt(bt(e), "getTabIndex", function (t, r) {
        var a = t || e.props.selected,
            n = r || e.props.preSelection;
        return e.isKeyboardSelected() || e.isSameDay(a) && $t(n, a) ? 0 : -1;
      }), vt(bt(e), "handleFocusDay", function () {
        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            r = !1;
        0 === e.getTabIndex() && !t.isInputFocused && e.isSameDay(e.props.preSelection) && (document.activeElement && document.activeElement !== document.body || (r = !0), e.props.inline && !e.props.shouldFocusDayInline && (r = !1), e.props.containerRef && e.props.containerRef.current && e.props.containerRef.current.contains(document.activeElement) && document.activeElement.classList.contains("react-datepicker__day") && (r = !0)), r && e.dayEl.current.focus({
          preventScroll: !0
        });
      }), vt(bt(e), "renderDayContents", function () {
        return e.props.monthShowsDuplicateDaysEnd && e.isAfterMonth() || e.props.monthShowsDuplicateDaysStart && e.isBeforeMonth() ? null : e.props.renderDayContents ? e.props.renderDayContents(Ne.default(e.props.day), e.props.day) : Ne.default(e.props.day);
      }), vt(bt(e), "render", function () {
        return de.default.createElement("div", {
          ref: e.dayEl,
          className: e.getClassNames(e.props.day),
          onKeyDown: e.handleOnKeyDown,
          onClick: e.handleClick,
          onMouseEnter: e.handleMouseEnter,
          tabIndex: e.getTabIndex(),
          "aria-label": e.getAriaLabel(),
          role: "button",
          "aria-disabled": e.isDisabled(),
          "aria-current": e.isCurrentDay() ? "date" : void 0,
          "aria-selected": e.isSelected() ? "true" : void 0
        }, e.renderDayContents());
      }), e;
    }

    return yt(r, [{
      key: "componentDidMount",
      value: function () {
        this.handleFocusDay();
      }
    }, {
      key: "componentDidUpdate",
      value: function (e) {
        this.handleFocusDay(e);
      }
    }]), r;
  }(de.default.Component),
      Lr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "handleClick", function (t) {
        e.props.onClick && e.props.onClick(t);
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e = (this || _global).props,
            t = e.weekNumber,
            r = e.ariaLabelPrefix,
            a = void 0 === r ? "week " : r,
            n = {
          "react-datepicker__week-number": !0,
          "react-datepicker__week-number--clickable": !!e.onClick
        };
        return de.default.createElement("div", {
          className: ce.default(n),
          "aria-label": "".concat(a, " ").concat((this || _global).props.weekNumber),
          onClick: (this || _global).handleClick
        }, t);
      }
    }]), r;
  }(de.default.Component),
      Rr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "handleDayClick", function (t, r) {
        e.props.onDayClick && e.props.onDayClick(t, r);
      }), vt(bt(e), "handleDayMouseEnter", function (t) {
        e.props.onDayMouseEnter && e.props.onDayMouseEnter(t);
      }), vt(bt(e), "handleWeekClick", function (t, r, a) {
        "function" == typeof e.props.onWeekSelect && e.props.onWeekSelect(t, r, a), e.props.shouldCloseOnSelect && e.props.setOpen(!1);
      }), vt(bt(e), "formatWeekNumber", function (t) {
        return e.props.formatWeekNumber ? e.props.formatWeekNumber(t) : At(t);
      }), vt(bt(e), "renderDays", function () {
        var t = Bt(e.props.day, e.props.locale, e.props.calendarStartDay),
            r = [],
            a = e.formatWeekNumber(t);

        if (e.props.showWeekNumber) {
          var n = e.props.onWeekSelect ? e.handleWeekClick.bind(bt(e), t, a) : void 0;
          r.push(de.default.createElement(Lr, {
            key: "W",
            weekNumber: a,
            onClick: n,
            ariaLabelPrefix: e.props.ariaLabelPrefix
          }));
        }

        return r.concat([0, 1, 2, 3, 4, 5, 6].map(function (r) {
          var a = ve.default(t, r);
          return de.default.createElement(Tr, {
            ariaLabelPrefixWhenEnabled: e.props.chooseDayAriaLabelPrefix,
            ariaLabelPrefixWhenDisabled: e.props.disabledDayAriaLabelPrefix,
            key: a.valueOf(),
            day: a,
            month: e.props.month,
            onClick: e.handleDayClick.bind(bt(e), a),
            onMouseEnter: e.handleDayMouseEnter.bind(bt(e), a),
            minDate: e.props.minDate,
            maxDate: e.props.maxDate,
            excludeDates: e.props.excludeDates,
            excludeDateIntervals: e.props.excludeDateIntervals,
            includeDates: e.props.includeDates,
            includeDateIntervals: e.props.includeDateIntervals,
            highlightDates: e.props.highlightDates,
            selectingDate: e.props.selectingDate,
            filterDate: e.props.filterDate,
            preSelection: e.props.preSelection,
            selected: e.props.selected,
            selectsStart: e.props.selectsStart,
            selectsEnd: e.props.selectsEnd,
            selectsRange: e.props.selectsRange,
            selectsDisabledDaysInRange: e.props.selectsDisabledDaysInRange,
            startDate: e.props.startDate,
            endDate: e.props.endDate,
            dayClassName: e.props.dayClassName,
            renderDayContents: e.props.renderDayContents,
            disabledKeyboardNavigation: e.props.disabledKeyboardNavigation,
            handleOnKeyDown: e.props.handleOnKeyDown,
            isInputFocused: e.props.isInputFocused,
            containerRef: e.props.containerRef,
            inline: e.props.inline,
            shouldFocusDayInline: e.props.shouldFocusDayInline,
            monthShowsDuplicateDaysEnd: e.props.monthShowsDuplicateDaysEnd,
            monthShowsDuplicateDaysStart: e.props.monthShowsDuplicateDaysStart,
            locale: e.props.locale
          });
        }));
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        return de.default.createElement("div", {
          className: "react-datepicker__week"
        }, this.renderDays());
      }
    }], [{
      key: "defaultProps",
      get: function () {
        return {
          shouldCloseOnSelect: !0
        };
      }
    }]), r;
  }(de.default.Component),
      Fr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "MONTH_REFS", _t(Array(12)).map(function () {
        return de.default.createRef();
      })), vt(bt(e), "isDisabled", function (t) {
        return rr(t, e.props);
      }), vt(bt(e), "isExcluded", function (t) {
        return ar(t, e.props);
      }), vt(bt(e), "handleDayClick", function (t, r) {
        e.props.onDayClick && e.props.onDayClick(t, r, e.props.orderInDisplay);
      }), vt(bt(e), "handleDayMouseEnter", function (t) {
        e.props.onDayMouseEnter && e.props.onDayMouseEnter(t);
      }), vt(bt(e), "handleMouseLeave", function () {
        e.props.onMouseLeave && e.props.onMouseLeave();
      }), vt(bt(e), "isRangeStartMonth", function (t) {
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.endDate;
        return !(!n || !o) && Vt(Ae.default(a, t), n);
      }), vt(bt(e), "isRangeStartQuarter", function (t) {
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.endDate;
        return !(!n || !o) && Ut(qe.default(a, t), n);
      }), vt(bt(e), "isRangeEndMonth", function (t) {
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.endDate;
        return !(!n || !o) && Vt(Ae.default(a, t), o);
      }), vt(bt(e), "isRangeEndQuarter", function (t) {
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.endDate;
        return !(!n || !o) && Ut(qe.default(a, t), o);
      }), vt(bt(e), "isWeekInMonth", function (t) {
        var r = e.props.day,
            a = ve.default(t, 6);
        return Vt(t, r) || Vt(a, r);
      }), vt(bt(e), "isCurrentMonth", function (e, t) {
        return Ie.default(e) === Ie.default(xt()) && t === Ye.default(xt());
      }), vt(bt(e), "isSelectedMonth", function (e, t, r) {
        return Ye.default(e) === t && Ie.default(e) === Ie.default(r);
      }), vt(bt(e), "isSelectedQuarter", function (e, t, r) {
        return xe.default(e) === t && Ie.default(e) === Ie.default(r);
      }), vt(bt(e), "renderWeeks", function () {
        for (var t = [], r = e.props.fixedHeight, a = 0, n = !1, o = Bt(Kt(e.props.day), e.props.locale, e.props.calendarStartDay); t.push(de.default.createElement(Rr, {
          ariaLabelPrefix: e.props.weekAriaLabelPrefix,
          chooseDayAriaLabelPrefix: e.props.chooseDayAriaLabelPrefix,
          disabledDayAriaLabelPrefix: e.props.disabledDayAriaLabelPrefix,
          key: a,
          day: o,
          month: Ye.default(e.props.day),
          onDayClick: e.handleDayClick,
          onDayMouseEnter: e.handleDayMouseEnter,
          onWeekSelect: e.props.onWeekSelect,
          formatWeekNumber: e.props.formatWeekNumber,
          locale: e.props.locale,
          minDate: e.props.minDate,
          maxDate: e.props.maxDate,
          excludeDates: e.props.excludeDates,
          excludeDateIntervals: e.props.excludeDateIntervals,
          includeDates: e.props.includeDates,
          includeDateIntervals: e.props.includeDateIntervals,
          inline: e.props.inline,
          shouldFocusDayInline: e.props.shouldFocusDayInline,
          highlightDates: e.props.highlightDates,
          selectingDate: e.props.selectingDate,
          filterDate: e.props.filterDate,
          preSelection: e.props.preSelection,
          selected: e.props.selected,
          selectsStart: e.props.selectsStart,
          selectsEnd: e.props.selectsEnd,
          selectsRange: e.props.selectsRange,
          selectsDisabledDaysInRange: e.props.selectsDisabledDaysInRange,
          showWeekNumber: e.props.showWeekNumbers,
          startDate: e.props.startDate,
          endDate: e.props.endDate,
          dayClassName: e.props.dayClassName,
          setOpen: e.props.setOpen,
          shouldCloseOnSelect: e.props.shouldCloseOnSelect,
          disabledKeyboardNavigation: e.props.disabledKeyboardNavigation,
          renderDayContents: e.props.renderDayContents,
          handleOnKeyDown: e.props.handleOnKeyDown,
          isInputFocused: e.props.isInputFocused,
          containerRef: e.props.containerRef,
          calendarStartDay: e.props.calendarStartDay,
          monthShowsDuplicateDaysEnd: e.props.monthShowsDuplicateDaysEnd,
          monthShowsDuplicateDaysStart: e.props.monthShowsDuplicateDaysStart
        })), !n;) {
          a++, o = De.default(o, 1);
          var s = r && a >= 6,
              i = !r && !e.isWeekInMonth(o);

          if (s || i) {
            if (!e.props.peekNextMonth) break;
            n = !0;
          }
        }

        return t;
      }), vt(bt(e), "onMonthClick", function (t, r) {
        e.handleDayClick(Kt(Ae.default(e.props.day, r)), t);
      }), vt(bt(e), "handleMonthNavigation", function (t, r) {
        e.isDisabled(r) || e.isExcluded(r) || (e.props.setPreSelection(r), e.MONTH_REFS[t].current && e.MONTH_REFS[t].current.focus());
      }), vt(bt(e), "onMonthKeyDown", function (t, r) {
        var a = t.key;
        if (!e.props.disabledKeyboardNavigation) switch (a) {
          case "Enter":
            e.onMonthClick(t, r), e.props.setPreSelection(e.props.selected);
            break;

          case "ArrowRight":
            e.handleMonthNavigation(11 === r ? 0 : r + 1, we.default(e.props.preSelection, 1));
            break;

          case "ArrowLeft":
            e.handleMonthNavigation(0 === r ? 11 : r - 1, Se.default(e.props.preSelection, 1));
        }
      }), vt(bt(e), "onQuarterClick", function (t, r) {
        e.handleDayClick(jt(qe.default(e.props.day, r)), t);
      }), vt(bt(e), "getMonthClassNames", function (t) {
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.endDate,
            s = r.selected,
            i = r.minDate,
            p = r.maxDate,
            l = r.preSelection,
            d = r.monthClassName,
            c = d ? d(a) : void 0;
        return ce.default("react-datepicker__month-text", "react-datepicker__month-".concat(t), c, {
          "react-datepicker__month--disabled": (i || p) && nr(Ae.default(a, t), e.props),
          "react-datepicker__month--selected": e.isSelectedMonth(a, t, s),
          "react-datepicker__month-text--keyboard-selected": Ye.default(l) === t,
          "react-datepicker__month--in-range": or(n, o, t, a),
          "react-datepicker__month--range-start": e.isRangeStartMonth(t),
          "react-datepicker__month--range-end": e.isRangeEndMonth(t),
          "react-datepicker__month-text--today": e.isCurrentMonth(a, t)
        });
      }), vt(bt(e), "getTabIndex", function (t) {
        var r = Ye.default(e.props.preSelection);
        return e.props.disabledKeyboardNavigation || t !== r ? "-1" : "0";
      }), vt(bt(e), "getAriaLabel", function (t) {
        var r = e.props,
            a = r.ariaLabelPrefix,
            n = void 0 === a ? "Choose" : a,
            o = r.disabledDayAriaLabelPrefix,
            s = void 0 === o ? "Not available" : o,
            i = r.day,
            p = Ae.default(i, t),
            l = e.isDisabled(p) || e.isExcluded(p) ? s : n;
        return "".concat(l, " ").concat(Lt(p, "MMMM yyyy"));
      }), vt(bt(e), "getQuarterClassNames", function (t) {
        var r = e.props,
            a = r.day,
            n = r.startDate,
            o = r.endDate,
            s = r.selected,
            i = r.minDate,
            p = r.maxDate;
        return ce.default("react-datepicker__quarter-text", "react-datepicker__quarter-".concat(t), {
          "react-datepicker__quarter--disabled": (i || p) && sr(qe.default(a, t), e.props),
          "react-datepicker__quarter--selected": e.isSelectedQuarter(a, t, s),
          "react-datepicker__quarter--in-range": pr(n, o, t, a),
          "react-datepicker__quarter--range-start": e.isRangeStartQuarter(t),
          "react-datepicker__quarter--range-end": e.isRangeEndQuarter(t)
        });
      }), vt(bt(e), "renderMonths", function () {
        var t = e.props,
            r = t.showFullMonthYearPicker,
            a = t.showTwoColumnMonthYearPicker,
            n = t.showFourColumnMonthYearPicker,
            o = t.locale,
            s = t.day,
            i = t.selected;
        return (n ? [[0, 1, 2, 3], [4, 5, 6, 7], [8, 9, 10, 11]] : a ? [[0, 1], [2, 3], [4, 5], [6, 7], [8, 9], [10, 11]] : [[0, 1, 2], [3, 4, 5], [6, 7, 8], [9, 10, 11]]).map(function (t, a) {
          return de.default.createElement("div", {
            className: "react-datepicker__month-wrapper",
            key: a
          }, t.map(function (t, a) {
            return de.default.createElement("div", {
              ref: e.MONTH_REFS[t],
              key: a,
              onClick: function (r) {
                e.onMonthClick(r, t);
              },
              onKeyDown: function (r) {
                e.onMonthKeyDown(r, t);
              },
              tabIndex: e.getTabIndex(t),
              className: e.getMonthClassNames(t),
              role: "button",
              "aria-label": e.getAriaLabel(t),
              "aria-current": e.isCurrentMonth(s, t) ? "date" : void 0,
              "aria-selected": e.isSelectedMonth(s, t, i) ? "true" : void 0
            }, r ? Zt(t, o) : er(t, o));
          }));
        });
      }), vt(bt(e), "renderQuarters", function () {
        var t = e.props,
            r = t.day,
            a = t.selected;
        return de.default.createElement("div", {
          className: "react-datepicker__quarter-wrapper"
        }, [1, 2, 3, 4].map(function (t, n) {
          return de.default.createElement("div", {
            key: n,
            onClick: function (r) {
              e.onQuarterClick(r, t);
            },
            className: e.getQuarterClassNames(t),
            "aria-selected": e.isSelectedQuarter(r, t, a) ? "true" : void 0
          }, tr(t, e.props.locale));
        }));
      }), vt(bt(e), "getClassNames", function () {
        var t = e.props;
        t.day;
        var r = t.selectingDate,
            a = t.selectsStart,
            n = t.selectsEnd,
            o = t.showMonthYearPicker,
            s = t.showQuarterYearPicker;
        return ce.default("react-datepicker__month", {
          "react-datepicker__month--selecting-range": r && (a || n)
        }, {
          "react-datepicker__monthPicker": o
        }, {
          "react-datepicker__quarterPicker": s
        });
      }), e;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e = (this || _global).props,
            t = e.showMonthYearPicker,
            r = e.showQuarterYearPicker,
            a = e.day,
            n = e.ariaLabelPrefix,
            o = void 0 === n ? "month " : n;
        return de.default.createElement("div", {
          className: this.getClassNames(),
          onMouseLeave: (this || _global).handleMouseLeave,
          "aria-label": "".concat(o, " ").concat(Lt(a, "yyyy-MM"))
        }, t ? this.renderMonths() : r ? this.renderQuarters() : this.renderWeeks());
      }
    }]), r;
  }(de.default.Component),
      Ar = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      var e;
      ht(this || _global, r);

      for (var a = arguments.length, n = new Array(a), o = 0; o < a; o++) n[o] = arguments[o];

      return vt(bt(e = t.call.apply(t, [this || _global].concat(n))), "state", {
        height: null
      }), vt(bt(e), "handleClick", function (t) {
        (e.props.minTime || e.props.maxTime) && ur(t, e.props) || (e.props.excludeTimes || e.props.includeTimes || e.props.filterTime) && cr(t, e.props) || e.props.onChange(t);
      }), vt(bt(e), "isSelectedTime", function (t, r, a) {
        return e.props.selected && r === Pe.default(t) && a === Me.default(t);
      }), vt(bt(e), "liClasses", function (t, r, a) {
        var n = ["react-datepicker__time-list-item", e.props.timeClassName ? e.props.timeClassName(t, r, a) : void 0];
        return e.isSelectedTime(t, r, a) && n.push("react-datepicker__time-list-item--selected"), ((e.props.minTime || e.props.maxTime) && ur(t, e.props) || (e.props.excludeTimes || e.props.includeTimes || e.props.filterTime) && cr(t, e.props)) && n.push("react-datepicker__time-list-item--disabled"), e.props.injectTimes && (60 * Pe.default(t) + Me.default(t)) % e.props.intervals != 0 && n.push("react-datepicker__time-list-item--injected"), n.join(" ");
      }), vt(bt(e), "handleOnKeyDown", function (t, r) {
        " " === t.key && (t.preventDefault(), t.key = "Enter"), "Enter" === t.key && e.handleClick(r), e.props.handleOnKeyDown(t);
      }), vt(bt(e), "renderTimes", function () {
        for (var t = [], r = e.props.format ? e.props.format : "p", a = e.props.intervals, n = Wt(xt(e.props.selected)), o = 1440 / a, s = e.props.injectTimes && e.props.injectTimes.sort(function (e, t) {
          return e - t;
        }), i = e.props.selected || e.props.openToDate || xt(), p = Pe.default(i), l = Me.default(i), d = Fe.default(Re.default(n, l), p), c = 0; c < o; c++) {
          var u = me.default(n, c * a);

          if (t.push(u), s) {
            var f = gr(n, u, c, a, s);
            t = t.concat(f);
          }
        }

        return t.map(function (t, a) {
          return de.default.createElement("li", {
            key: a,
            onClick: e.handleClick.bind(bt(e), t),
            className: e.liClasses(t, p, l),
            ref: function (r) {
              (nt.default(t, d) || zt(t, d)) && (e.centerLi = r);
            },
            onKeyDown: function (r) {
              e.handleOnKeyDown(r, t);
            },
            tabIndex: "0",
            "aria-selected": e.isSelectedTime(t, p, l) ? "true" : void 0
          }, Lt(t, r, e.props.locale));
        });
      }), e;
    }

    return yt(r, [{
      key: "componentDidMount",
      value: function () {
        (this || _global).list.scrollTop = r.calcCenterPosition((this || _global).props.monthRef ? (this || _global).props.monthRef.clientHeight - (this || _global).header.clientHeight : (this || _global).list.clientHeight, (this || _global).centerLi), (this || _global).props.monthRef && (this || _global).header && this.setState({
          height: (this || _global).props.monthRef.clientHeight - (this || _global).header.clientHeight
        });
      }
    }, {
      key: "render",
      value: function () {
        var e = this || _global,
            t = (this || _global).state.height;
        return de.default.createElement("div", {
          className: "react-datepicker__time-container ".concat((this || _global).props.todayButton ? "react-datepicker__time-container--with-today-button" : "")
        }, de.default.createElement("div", {
          className: "react-datepicker__header react-datepicker__header--time ".concat((this || _global).props.showTimeSelectOnly ? "react-datepicker__header--time--only" : ""),
          ref: function (t) {
            e.header = t;
          }
        }, de.default.createElement("div", {
          className: "react-datepicker-time__header"
        }, (this || _global).props.timeCaption)), de.default.createElement("div", {
          className: "react-datepicker__time"
        }, de.default.createElement("div", {
          className: "react-datepicker__time-box"
        }, de.default.createElement("ul", {
          className: "react-datepicker__time-list",
          ref: function (t) {
            e.list = t;
          },
          style: t ? {
            height: t
          } : {},
          tabIndex: "0"
        }, this.renderTimes()))));
      }
    }], [{
      key: "defaultProps",
      get: function () {
        return {
          intervals: 30,
          onTimeChange: function () {},
          todayButton: null,
          timeCaption: "Time"
        };
      }
    }]), r;
  }(de.default.Component);

  vt(Ar, "calcCenterPosition", function (e, t) {
    return t.offsetTop - (e / 2 - t.clientHeight / 2);
  });

  var qr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), vt(bt(a = t.call(this || _global, e)), "YEAR_REFS", _t(Array(a.props.yearItemNumber)).map(function () {
        return de.default.createRef();
      })), vt(bt(a), "isDisabled", function (e) {
        return rr(e, a.props);
      }), vt(bt(a), "isExcluded", function (e) {
        return ar(e, a.props);
      }), vt(bt(a), "updateFocusOnPaginate", function (e) {
        var t = function () {
          (this || _global).YEAR_REFS[e].current.focus();
        }.bind(bt(a));

        window.requestAnimationFrame(t);
      }), vt(bt(a), "handleYearClick", function (e, t) {
        a.props.onDayClick && a.props.onDayClick(e, t);
      }), vt(bt(a), "handleYearNavigation", function (e, t) {
        var r = a.props,
            n = r.date,
            o = r.yearItemNumber,
            s = br(n, o).startPeriod;
        a.isDisabled(t) || a.isExcluded(t) || (a.props.setPreSelection(t), e - s == -1 ? a.updateFocusOnPaginate(o - 1) : e - s === o ? a.updateFocusOnPaginate(0) : a.YEAR_REFS[e - s].current.focus());
      }), vt(bt(a), "isSameDay", function (e, t) {
        return $t(e, t);
      }), vt(bt(a), "isCurrentYear", function (e) {
        return e === Ie.default(xt());
      }), vt(bt(a), "isKeyboardSelected", function (e) {
        var t = Ht(We.default(a.props.date, e));
        return !a.props.disabledKeyboardNavigation && !a.props.inline && !$t(t, Ht(a.props.selected)) && $t(t, Ht(a.props.preSelection));
      }), vt(bt(a), "onYearClick", function (e, t) {
        var r = a.props.date;
        a.handleYearClick(Ht(We.default(r, t)), e);
      }), vt(bt(a), "onYearKeyDown", function (e, t) {
        var r = e.key;
        if (!a.props.disabledKeyboardNavigation) switch (r) {
          case "Enter":
            a.onYearClick(e, t), a.props.setPreSelection(a.props.selected);
            break;

          case "ArrowRight":
            a.handleYearNavigation(t + 1, ge.default(a.props.preSelection, 1));
            break;

          case "ArrowLeft":
            a.handleYearNavigation(t - 1, Ce.default(a.props.preSelection, 1));
        }
      }), vt(bt(a), "getYearClassNames", function (e) {
        var t = a.props,
            r = t.minDate,
            n = t.maxDate,
            o = t.selected;
        return ce.default("react-datepicker__year-text", {
          "react-datepicker__year-text--selected": e === Ie.default(o),
          "react-datepicker__year-text--disabled": (r || n) && ir(e, a.props),
          "react-datepicker__year-text--keyboard-selected": a.isKeyboardSelected(e),
          "react-datepicker__year-text--today": a.isCurrentYear(e)
        });
      }), vt(bt(a), "getYearTabIndex", function (e) {
        return a.props.disabledKeyboardNavigation ? "-1" : e === Ie.default(a.props.preSelection) ? "0" : "-1";
      }), a;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        for (var e = this || _global, t = [], r = (this || _global).props, a = br(r.date, r.yearItemNumber), n = a.startPeriod, o = a.endPeriod, s = function (r) {
          t.push(de.default.createElement("div", {
            ref: e.YEAR_REFS[r - n],
            onClick: function (t) {
              e.onYearClick(t, r);
            },
            onKeyDown: function (t) {
              e.onYearKeyDown(t, r);
            },
            tabIndex: e.getYearTabIndex(r),
            className: e.getYearClassNames(r),
            key: r,
            "aria-current": e.isCurrentYear(r) ? "date" : void 0
          }, r));
        }, i = n; i <= o; i++) s(i);

        return de.default.createElement("div", {
          className: "react-datepicker__year"
        }, de.default.createElement("div", {
          className: "react-datepicker__year-wrapper"
        }, t));
      }
    }]), r;
  }(de.default.Component),
      Wr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), vt(bt(a = t.call(this || _global, e)), "onTimeChange", function (e) {
        a.setState({
          time: e
        });
        var t = new Date();
        t.setHours(e.split(":")[0]), t.setMinutes(e.split(":")[1]), a.props.onChange(t);
      }), vt(bt(a), "renderTimeInput", function () {
        var e = a.state.time,
            t = a.props,
            r = t.date,
            n = t.timeString,
            o = t.customTimeInput;
        return o ? de.default.cloneElement(o, {
          date: r,
          value: e,
          onChange: a.onTimeChange
        }) : de.default.createElement("input", {
          type: "time",
          className: "react-datepicker-time__input",
          placeholder: "Time",
          name: "time-input",
          required: !0,
          value: e,
          onChange: function (e) {
            a.onTimeChange(e.target.value || n);
          }
        });
      }), a.state = {
        time: a.props.timeString
      }, a;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        return de.default.createElement("div", {
          className: "react-datepicker__input-time-container"
        }, de.default.createElement("div", {
          className: "react-datepicker-time__caption"
        }, (this || _global).props.timeInputLabel), de.default.createElement("div", {
          className: "react-datepicker-time__input-container"
        }, de.default.createElement("div", {
          className: "react-datepicker-time__input"
        }, this.renderTimeInput())));
      }
    }], [{
      key: "getDerivedStateFromProps",
      value: function (e, t) {
        return e.timeString !== t.time ? {
          time: e.timeString
        } : null;
      }
    }]), r;
  }(de.default.Component);

  function Br(e) {
    var t = e.className,
        r = e.children,
        a = e.showPopperArrow,
        n = e.arrowProps,
        o = void 0 === n ? {} : n;
    return de.default.createElement("div", {
      className: t
    }, a && de.default.createElement("div", Dt({
      className: "react-datepicker__triangle"
    }, o)), r);
  }

  var Kr = ["react-datepicker__year-select", "react-datepicker__month-select", "react-datepicker__month-year-select"],
      Hr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), vt(bt(a = t.call(this || _global, e)), "handleClickOutside", function (e) {
        a.props.onClickOutside(e);
      }), vt(bt(a), "setClickOutsideRef", function () {
        return a.containerRef.current;
      }), vt(bt(a), "handleDropdownFocus", function (e) {
        (function () {
          var e = ((arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}).className || "").split(/\s+/);
          return Kr.some(function (t) {
            return e.indexOf(t) >= 0;
          });
        })(e.target) && a.props.onDropdownFocus();
      }), vt(bt(a), "getDateInView", function () {
        var e = a.props,
            t = e.preSelection,
            r = e.selected,
            n = e.openToDate,
            o = vr(a.props),
            s = Dr(a.props),
            i = xt(),
            p = n || r || t;
        return p || (o && nt.default(i, o) ? o : s && at.default(i, s) ? s : i);
      }), vt(bt(a), "increaseMonth", function () {
        a.setState(function (e) {
          var t = e.date;
          return {
            date: we.default(t, 1)
          };
        }, function () {
          return a.handleMonthChange(a.state.date);
        });
      }), vt(bt(a), "decreaseMonth", function () {
        a.setState(function (e) {
          var t = e.date;
          return {
            date: Se.default(t, 1)
          };
        }, function () {
          return a.handleMonthChange(a.state.date);
        });
      }), vt(bt(a), "handleDayClick", function (e, t, r) {
        a.props.onSelect(e, t, r), a.props.setPreSelection && a.props.setPreSelection(e);
      }), vt(bt(a), "handleDayMouseEnter", function (e) {
        a.setState({
          selectingDate: e
        }), a.props.onDayMouseEnter && a.props.onDayMouseEnter(e);
      }), vt(bt(a), "handleMonthMouseLeave", function () {
        a.setState({
          selectingDate: null
        }), a.props.onMonthMouseLeave && a.props.onMonthMouseLeave();
      }), vt(bt(a), "handleYearChange", function (e) {
        a.props.onYearChange && a.props.onYearChange(e), a.props.adjustDateOnChange && (a.props.onSelect && a.props.onSelect(e), a.props.setOpen && a.props.setOpen(!0)), a.props.setPreSelection && a.props.setPreSelection(e);
      }), vt(bt(a), "handleMonthChange", function (e) {
        a.props.onMonthChange && a.props.onMonthChange(e), a.props.adjustDateOnChange && (a.props.onSelect && a.props.onSelect(e), a.props.setOpen && a.props.setOpen(!0)), a.props.setPreSelection && a.props.setPreSelection(e);
      }), vt(bt(a), "handleMonthYearChange", function (e) {
        a.handleYearChange(e), a.handleMonthChange(e);
      }), vt(bt(a), "changeYear", function (e) {
        a.setState(function (t) {
          var r = t.date;
          return {
            date: We.default(r, e)
          };
        }, function () {
          return a.handleYearChange(a.state.date);
        });
      }), vt(bt(a), "changeMonth", function (e) {
        a.setState(function (t) {
          var r = t.date;
          return {
            date: Ae.default(r, e)
          };
        }, function () {
          return a.handleMonthChange(a.state.date);
        });
      }), vt(bt(a), "changeMonthYear", function (e) {
        a.setState(function (t) {
          var r = t.date;
          return {
            date: We.default(Ae.default(r, Ye.default(e)), Ie.default(e))
          };
        }, function () {
          return a.handleMonthYearChange(a.state.date);
        });
      }), vt(bt(a), "header", function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : a.state.date,
            t = Bt(e, a.props.locale, a.props.calendarStartDay),
            r = [];
        return a.props.showWeekNumbers && r.push(de.default.createElement("div", {
          key: "W",
          className: "react-datepicker__day-name"
        }, a.props.weekLabel || "#")), r.concat([0, 1, 2, 3, 4, 5, 6].map(function (e) {
          var r = ve.default(t, e),
              n = a.formatWeekday(r, a.props.locale),
              o = a.props.weekDayClassName ? a.props.weekDayClassName(r) : void 0;
          return de.default.createElement("div", {
            key: e,
            className: ce.default("react-datepicker__day-name", o)
          }, n);
        }));
      }), vt(bt(a), "formatWeekday", function (e, t) {
        return a.props.formatWeekDay ? function (e, t, r) {
          return t(Lt(e, "EEEE", r));
        }(e, a.props.formatWeekDay, t) : a.props.useWeekdaysShort ? function (e, t) {
          return Lt(e, "EEE", t);
        }(e, t) : function (e, t) {
          return Lt(e, "EEEEEE", t);
        }(e, t);
      }), vt(bt(a), "decreaseYear", function () {
        a.setState(function (e) {
          var t = e.date;
          return {
            date: Ce.default(t, a.props.showYearPicker ? a.props.yearItemNumber : 1)
          };
        }, function () {
          return a.handleYearChange(a.state.date);
        });
      }), vt(bt(a), "renderPreviousButton", function () {
        if (!a.props.renderCustomHeader) {
          var e;

          switch (!0) {
            case a.props.showMonthYearPicker:
              e = mr(a.state.date, a.props);
              break;

            case a.props.showYearPicker:
              e = function (e) {
                var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                    r = t.minDate,
                    a = t.yearItemNumber,
                    n = void 0 === a ? Ot : a,
                    o = br(Ht(Ce.default(e, n)), n).endPeriod,
                    s = r && Ie.default(r);
                return s && s > o || !1;
              }(a.state.date, a.props);

              break;

            default:
              e = fr(a.state.date, a.props);
          }

          if ((a.props.forceShowMonthNavigation || a.props.showDisabledMonthNavigation || !e) && !a.props.showTimeSelectOnly) {
            var t = ["react-datepicker__navigation", "react-datepicker__navigation--previous"],
                r = a.decreaseMonth;
            (a.props.showMonthYearPicker || a.props.showQuarterYearPicker || a.props.showYearPicker) && (r = a.decreaseYear), e && a.props.showDisabledMonthNavigation && (t.push("react-datepicker__navigation--previous--disabled"), r = null);
            var n = a.props.showMonthYearPicker || a.props.showQuarterYearPicker || a.props.showYearPicker,
                o = a.props,
                s = o.previousMonthButtonLabel,
                i = o.previousYearButtonLabel,
                p = a.props,
                l = p.previousMonthAriaLabel,
                d = void 0 === l ? "string" == typeof s ? s : "Previous Month" : l,
                c = p.previousYearAriaLabel,
                u = void 0 === c ? "string" == typeof i ? i : "Previous Year" : c;
            return de.default.createElement("button", {
              type: "button",
              className: t.join(" "),
              onClick: r,
              onKeyDown: a.props.handleOnKeyDown,
              "aria-label": n ? u : d
            }, de.default.createElement("span", {
              className: ["react-datepicker__navigation-icon", "react-datepicker__navigation-icon--previous"].join(" ")
            }, n ? a.props.previousYearButtonLabel : a.props.previousMonthButtonLabel));
          }
        }
      }), vt(bt(a), "increaseYear", function () {
        a.setState(function (e) {
          var t = e.date;
          return {
            date: ge.default(t, a.props.showYearPicker ? a.props.yearItemNumber : 1)
          };
        }, function () {
          return a.handleYearChange(a.state.date);
        });
      }), vt(bt(a), "renderNextButton", function () {
        if (!a.props.renderCustomHeader) {
          var e;

          switch (!0) {
            case a.props.showMonthYearPicker:
              e = yr(a.state.date, a.props);
              break;

            case a.props.showYearPicker:
              e = function (e) {
                var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                    r = t.maxDate,
                    a = t.yearItemNumber,
                    n = void 0 === a ? Ot : a,
                    o = br(ge.default(e, n), n).startPeriod,
                    s = r && Ie.default(r);
                return s && s < o || !1;
              }(a.state.date, a.props);

              break;

            default:
              e = hr(a.state.date, a.props);
          }

          if ((a.props.forceShowMonthNavigation || a.props.showDisabledMonthNavigation || !e) && !a.props.showTimeSelectOnly) {
            var t = ["react-datepicker__navigation", "react-datepicker__navigation--next"];
            a.props.showTimeSelect && t.push("react-datepicker__navigation--next--with-time"), a.props.todayButton && t.push("react-datepicker__navigation--next--with-today-button");
            var r = a.increaseMonth;
            (a.props.showMonthYearPicker || a.props.showQuarterYearPicker || a.props.showYearPicker) && (r = a.increaseYear), e && a.props.showDisabledMonthNavigation && (t.push("react-datepicker__navigation--next--disabled"), r = null);
            var n = a.props.showMonthYearPicker || a.props.showQuarterYearPicker || a.props.showYearPicker,
                o = a.props,
                s = o.nextMonthButtonLabel,
                i = o.nextYearButtonLabel,
                p = a.props,
                l = p.nextMonthAriaLabel,
                d = void 0 === l ? "string" == typeof s ? s : "Next Month" : l,
                c = p.nextYearAriaLabel,
                u = void 0 === c ? "string" == typeof i ? i : "Next Year" : c;
            return de.default.createElement("button", {
              type: "button",
              className: t.join(" "),
              onClick: r,
              onKeyDown: a.props.handleOnKeyDown,
              "aria-label": n ? u : d
            }, de.default.createElement("span", {
              className: ["react-datepicker__navigation-icon", "react-datepicker__navigation-icon--next"].join(" ")
            }, n ? a.props.nextYearButtonLabel : a.props.nextMonthButtonLabel));
          }
        }
      }), vt(bt(a), "renderCurrentMonth", function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : a.state.date,
            t = ["react-datepicker__current-month"];
        return a.props.showYearDropdown && t.push("react-datepicker__current-month--hasYearDropdown"), a.props.showMonthDropdown && t.push("react-datepicker__current-month--hasMonthDropdown"), a.props.showMonthYearDropdown && t.push("react-datepicker__current-month--hasMonthYearDropdown"), de.default.createElement("div", {
          className: t.join(" ")
        }, Lt(e, a.props.dateFormat, a.props.locale));
      }), vt(bt(a), "renderYearDropdown", function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
        if (a.props.showYearDropdown && !e) return de.default.createElement(Mr, {
          adjustDateOnChange: a.props.adjustDateOnChange,
          date: a.state.date,
          onSelect: a.props.onSelect,
          setOpen: a.props.setOpen,
          dropdownMode: a.props.dropdownMode,
          onChange: a.changeYear,
          minDate: a.props.minDate,
          maxDate: a.props.maxDate,
          year: Ie.default(a.state.date),
          scrollableYearDropdown: a.props.scrollableYearDropdown,
          yearDropdownItemNumber: a.props.yearDropdownItemNumber
        });
      }), vt(bt(a), "renderMonthDropdown", function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
        if (a.props.showMonthDropdown && !e) return de.default.createElement(Nr, {
          dropdownMode: a.props.dropdownMode,
          locale: a.props.locale,
          onChange: a.changeMonth,
          month: Ye.default(a.state.date),
          useShortMonthInDropdown: a.props.useShortMonthInDropdown
        });
      }), vt(bt(a), "renderMonthYearDropdown", function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
        if (a.props.showMonthYearDropdown && !e) return de.default.createElement(Ir, {
          dropdownMode: a.props.dropdownMode,
          locale: a.props.locale,
          dateFormat: a.props.dateFormat,
          onChange: a.changeMonthYear,
          minDate: a.props.minDate,
          maxDate: a.props.maxDate,
          date: a.state.date,
          scrollableMonthYearDropdown: a.props.scrollableMonthYearDropdown
        });
      }), vt(bt(a), "renderTodayButton", function () {
        if (a.props.todayButton && !a.props.showTimeSelectOnly) return de.default.createElement("div", {
          className: "react-datepicker__today-button",
          onClick: function (e) {
            return a.props.onSelect(Ve.default(xt()), e);
          }
        }, a.props.todayButton);
      }), vt(bt(a), "renderDefaultHeader", function (e) {
        var t = e.monthDate,
            r = e.i;
        return de.default.createElement("div", {
          className: "react-datepicker__header ".concat(a.props.showTimeSelect ? "react-datepicker__header--has-time-select" : "")
        }, a.renderCurrentMonth(t), de.default.createElement("div", {
          className: "react-datepicker__header__dropdown react-datepicker__header__dropdown--".concat(a.props.dropdownMode),
          onFocus: a.handleDropdownFocus
        }, a.renderMonthDropdown(0 !== r), a.renderMonthYearDropdown(0 !== r), a.renderYearDropdown(0 !== r)), de.default.createElement("div", {
          className: "react-datepicker__day-names"
        }, a.header(t)));
      }), vt(bt(a), "renderCustomHeader", function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            t = e.monthDate,
            r = e.i;
        if (a.props.showTimeSelect && !a.state.monthContainer || a.props.showTimeSelectOnly) return null;
        var n = fr(a.state.date, a.props),
            o = hr(a.state.date, a.props),
            s = mr(a.state.date, a.props),
            i = yr(a.state.date, a.props),
            p = !a.props.showMonthYearPicker && !a.props.showQuarterYearPicker && !a.props.showYearPicker;
        return de.default.createElement("div", {
          className: "react-datepicker__header react-datepicker__header--custom",
          onFocus: a.props.onDropdownFocus
        }, a.props.renderCustomHeader(ut(ut({}, a.state), {}, {
          customHeaderCount: r,
          monthDate: t,
          changeMonth: a.changeMonth,
          changeYear: a.changeYear,
          decreaseMonth: a.decreaseMonth,
          increaseMonth: a.increaseMonth,
          decreaseYear: a.decreaseYear,
          increaseYear: a.increaseYear,
          prevMonthButtonDisabled: n,
          nextMonthButtonDisabled: o,
          prevYearButtonDisabled: s,
          nextYearButtonDisabled: i
        })), p && de.default.createElement("div", {
          className: "react-datepicker__day-names"
        }, a.header(t)));
      }), vt(bt(a), "renderYearHeader", function () {
        var e = a.state.date,
            t = a.props,
            r = t.showYearPicker,
            n = br(e, t.yearItemNumber),
            o = n.startPeriod,
            s = n.endPeriod;
        return de.default.createElement("div", {
          className: "react-datepicker__header react-datepicker-year-header"
        }, r ? "".concat(o, " - ").concat(s) : Ie.default(e));
      }), vt(bt(a), "renderHeader", function (e) {
        switch (!0) {
          case void 0 !== a.props.renderCustomHeader:
            return a.renderCustomHeader(e);

          case a.props.showMonthYearPicker || a.props.showQuarterYearPicker || a.props.showYearPicker:
            return a.renderYearHeader(e);

          default:
            return a.renderDefaultHeader(e);
        }
      }), vt(bt(a), "renderMonths", function () {
        if (!a.props.showTimeSelectOnly && !a.props.showYearPicker) {
          for (var e = [], t = a.props.showPreviousMonths ? a.props.monthsShown - 1 : 0, r = Se.default(a.state.date, t), n = 0; n < a.props.monthsShown; ++n) {
            var o = n - a.props.monthSelectedIn,
                s = we.default(r, o),
                i = "month-".concat(n),
                p = n < a.props.monthsShown - 1,
                l = n > 0;
            e.push(de.default.createElement("div", {
              key: i,
              ref: function (e) {
                a.monthContainer = e;
              },
              className: "react-datepicker__month-container"
            }, a.renderHeader({
              monthDate: s,
              i: n
            }), de.default.createElement(Fr, {
              chooseDayAriaLabelPrefix: a.props.chooseDayAriaLabelPrefix,
              disabledDayAriaLabelPrefix: a.props.disabledDayAriaLabelPrefix,
              weekAriaLabelPrefix: a.props.weekAriaLabelPrefix,
              onChange: a.changeMonthYear,
              day: s,
              dayClassName: a.props.dayClassName,
              calendarStartDay: a.props.calendarStartDay,
              monthClassName: a.props.monthClassName,
              onDayClick: a.handleDayClick,
              handleOnKeyDown: a.props.handleOnDayKeyDown,
              onDayMouseEnter: a.handleDayMouseEnter,
              onMouseLeave: a.handleMonthMouseLeave,
              onWeekSelect: a.props.onWeekSelect,
              orderInDisplay: n,
              formatWeekNumber: a.props.formatWeekNumber,
              locale: a.props.locale,
              minDate: a.props.minDate,
              maxDate: a.props.maxDate,
              excludeDates: a.props.excludeDates,
              excludeDateIntervals: a.props.excludeDateIntervals,
              highlightDates: a.props.highlightDates,
              selectingDate: a.state.selectingDate,
              includeDates: a.props.includeDates,
              includeDateIntervals: a.props.includeDateIntervals,
              inline: a.props.inline,
              shouldFocusDayInline: a.props.shouldFocusDayInline,
              fixedHeight: a.props.fixedHeight,
              filterDate: a.props.filterDate,
              preSelection: a.props.preSelection,
              setPreSelection: a.props.setPreSelection,
              selected: a.props.selected,
              selectsStart: a.props.selectsStart,
              selectsEnd: a.props.selectsEnd,
              selectsRange: a.props.selectsRange,
              selectsDisabledDaysInRange: a.props.selectsDisabledDaysInRange,
              showWeekNumbers: a.props.showWeekNumbers,
              startDate: a.props.startDate,
              endDate: a.props.endDate,
              peekNextMonth: a.props.peekNextMonth,
              setOpen: a.props.setOpen,
              shouldCloseOnSelect: a.props.shouldCloseOnSelect,
              renderDayContents: a.props.renderDayContents,
              disabledKeyboardNavigation: a.props.disabledKeyboardNavigation,
              showMonthYearPicker: a.props.showMonthYearPicker,
              showFullMonthYearPicker: a.props.showFullMonthYearPicker,
              showTwoColumnMonthYearPicker: a.props.showTwoColumnMonthYearPicker,
              showFourColumnMonthYearPicker: a.props.showFourColumnMonthYearPicker,
              showYearPicker: a.props.showYearPicker,
              showQuarterYearPicker: a.props.showQuarterYearPicker,
              isInputFocused: a.props.isInputFocused,
              containerRef: a.containerRef,
              monthShowsDuplicateDaysEnd: p,
              monthShowsDuplicateDaysStart: l
            })));
          }

          return e;
        }
      }), vt(bt(a), "renderYears", function () {
        if (!a.props.showTimeSelectOnly) return a.props.showYearPicker ? de.default.createElement("div", {
          className: "react-datepicker__year--container"
        }, a.renderHeader(), de.default.createElement(qr, Dt({
          onDayClick: a.handleDayClick,
          date: a.state.date
        }, a.props))) : void 0;
      }), vt(bt(a), "renderTimeSection", function () {
        if (a.props.showTimeSelect && (a.state.monthContainer || a.props.showTimeSelectOnly)) return de.default.createElement(Ar, {
          selected: a.props.selected,
          openToDate: a.props.openToDate,
          onChange: a.props.onTimeChange,
          timeClassName: a.props.timeClassName,
          format: a.props.timeFormat,
          includeTimes: a.props.includeTimes,
          intervals: a.props.timeIntervals,
          minTime: a.props.minTime,
          maxTime: a.props.maxTime,
          excludeTimes: a.props.excludeTimes,
          filterTime: a.props.filterTime,
          timeCaption: a.props.timeCaption,
          todayButton: a.props.todayButton,
          showMonthDropdown: a.props.showMonthDropdown,
          showMonthYearDropdown: a.props.showMonthYearDropdown,
          showYearDropdown: a.props.showYearDropdown,
          withPortal: a.props.withPortal,
          monthRef: a.state.monthContainer,
          injectTimes: a.props.injectTimes,
          locale: a.props.locale,
          handleOnKeyDown: a.props.handleOnKeyDown,
          showTimeSelectOnly: a.props.showTimeSelectOnly
        });
      }), vt(bt(a), "renderInputTimeSection", function () {
        var e = new Date(a.props.selected),
            t = Tt(e) && Boolean(a.props.selected) ? "".concat(kr(e.getHours()), ":").concat(kr(e.getMinutes())) : "";
        if (a.props.showTimeInput) return de.default.createElement(Wr, {
          date: e,
          timeString: t,
          timeInputLabel: a.props.timeInputLabel,
          onChange: a.props.onTimeChange,
          customTimeInput: a.props.customTimeInput
        });
      }), a.containerRef = de.default.createRef(), a.state = {
        date: a.getDateInView(),
        selectingDate: null,
        monthContainer: null
      }, a;
    }

    return yt(r, [{
      key: "componentDidMount",
      value: function () {
        var e = this || _global;
        (this || _global).props.showTimeSelect && ((this || _global).assignMonthContainer = void e.setState({
          monthContainer: e.monthContainer
        }));
      }
    }, {
      key: "componentDidUpdate",
      value: function (e) {
        (this || _global).props.preSelection && !$t((this || _global).props.preSelection, e.preSelection) ? this.setState({
          date: (this || _global).props.preSelection
        }) : (this || _global).props.openToDate && !$t((this || _global).props.openToDate, e.openToDate) && this.setState({
          date: (this || _global).props.openToDate
        });
      }
    }, {
      key: "render",
      value: function () {
        var e = (this || _global).props.container || Br;
        return de.default.createElement("div", {
          ref: (this || _global).containerRef
        }, de.default.createElement(e, {
          className: ce.default("react-datepicker", (this || _global).props.className, {
            "react-datepicker--time-only": (this || _global).props.showTimeSelectOnly
          }),
          showPopperArrow: (this || _global).props.showPopperArrow,
          arrowProps: (this || _global).props.arrowProps
        }, this.renderPreviousButton(), this.renderNextButton(), this.renderMonths(), this.renderYears(), this.renderTodayButton(), this.renderTimeSection(), this.renderInputTimeSection(), (this || _global).props.children));
      }
    }], [{
      key: "defaultProps",
      get: function () {
        return {
          onDropdownFocus: function () {},
          monthsShown: 1,
          monthSelectedIn: 0,
          forceShowMonthNavigation: !1,
          timeCaption: "Time",
          previousYearButtonLabel: "Previous Year",
          nextYearButtonLabel: "Next Year",
          previousMonthButtonLabel: "Previous Month",
          nextMonthButtonLabel: "Next Month",
          customTimeInput: null,
          yearItemNumber: Ot
        };
      }
    }]), r;
  }(de.default.Component),
      jr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), (a = t.call(this || _global, e)).el = document.createElement("div"), a;
    }

    return yt(r, [{
      key: "componentDidMount",
      value: function () {
        (this || _global).portalRoot = ((this || _global).props.portalHost || document).getElementById((this || _global).props.portalId), (this || _global).portalRoot || ((this || _global).portalRoot = document.createElement("div"), (this || _global).portalRoot.setAttribute("id", (this || _global).props.portalId), ((this || _global).props.portalHost || document.body).appendChild((this || _global).portalRoot)), (this || _global).portalRoot.appendChild((this || _global).el);
      }
    }, {
      key: "componentWillUnmount",
      value: function () {
        (this || _global).portalRoot.removeChild((this || _global).el);
      }
    }, {
      key: "render",
      value: function () {
        return dt.default.createPortal((this || _global).props.children, (this || _global).el);
      }
    }]), r;
  }(de.default.Component),
      Qr = function (e) {
    return !e.disabled && -1 !== e.tabIndex;
  },
      Vr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), vt(bt(a = t.call(this || _global, e)), "getTabChildren", function () {
        return Array.prototype.slice.call(a.tabLoopRef.current.querySelectorAll("[tabindex], a, button, input, select, textarea"), 1, -1).filter(Qr);
      }), vt(bt(a), "handleFocusStart", function (e) {
        var t = a.getTabChildren();
        t && t.length > 1 && t[t.length - 1].focus();
      }), vt(bt(a), "handleFocusEnd", function (e) {
        var t = a.getTabChildren();
        t && t.length > 1 && t[0].focus();
      }), a.tabLoopRef = de.default.createRef(), a;
    }

    return yt(r, [{
      key: "render",
      value: function () {
        return (this || _global).props.enableTabLoop ? de.default.createElement("div", {
          className: "react-datepicker__tab-loop",
          ref: (this || _global).tabLoopRef
        }, de.default.createElement("div", {
          className: "react-datepicker__tab-loop__start",
          tabIndex: "0",
          onFocus: (this || _global).handleFocusStart
        }), (this || _global).props.children, de.default.createElement("div", {
          className: "react-datepicker__tab-loop__end",
          tabIndex: "0",
          onFocus: (this || _global).handleFocusEnd
        })) : (this || _global).props.children;
      }
    }], [{
      key: "defaultProps",
      get: function () {
        return {
          enableTabLoop: !0
        };
      }
    }]), r;
  }(de.default.Component),
      Ur = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r() {
      return ht(this || _global, r), t.apply(this || _global, arguments);
    }

    return yt(r, [{
      key: "render",
      value: function () {
        var e,
            t = (this || _global).props,
            r = t.className,
            a = t.wrapperClassName,
            n = t.hidePopper,
            o = t.popperComponent,
            s = t.popperModifiers,
            i = t.popperPlacement,
            p = t.popperProps,
            l = t.targetComponent,
            d = t.enableTabLoop,
            c = t.popperOnKeyDown,
            u = t.portalId,
            f = t.portalHost;

        if (!n) {
          var h = ce.default("react-datepicker-popper", r);
          e = de.default.createElement(pe.Popper, Dt({
            modifiers: s,
            placement: i
          }, p), function (e) {
            var t = e.ref,
                r = e.style,
                a = e.placement,
                n = e.arrowProps;
            return de.default.createElement(Vr, {
              enableTabLoop: d
            }, de.default.createElement("div", {
              ref: t,
              style: r,
              className: h,
              "data-placement": a,
              onKeyDown: c
            }, de.default.cloneElement(o, {
              arrowProps: n
            })));
          });
        }

        (this || _global).props.popperContainer && (e = de.default.createElement((this || _global).props.popperContainer, {}, e)), u && !n && (e = de.default.createElement(jr, {
          portalId: u,
          portalHost: f
        }, e));
        var m = ce.default("react-datepicker-wrapper", a);
        return de.default.createElement(pe.Manager, {
          className: "react-datepicker-manager"
        }, de.default.createElement(pe.Reference, null, function (e) {
          var t = e.ref;
          return de.default.createElement("div", {
            ref: t,
            className: m
          }, l);
        }), e);
      }
    }], [{
      key: "defaultProps",
      get: function () {
        return {
          hidePopper: !0,
          popperModifiers: [],
          popperProps: {},
          popperPlacement: "bottom-start"
        };
      }
    }]), r;
  }(de.default.Component),
      $r = "react-datepicker-ignore-onclickoutside",
      zr = lt.default(Hr);

  var Gr = "Date input not valid.",
      Jr = function (e) {
    wt(r, e);
    var t = Ct(r);

    function r(e) {
      var a;
      return ht(this || _global, r), vt(bt(a = t.call(this || _global, e)), "getPreSelection", function () {
        return a.props.openToDate ? a.props.openToDate : a.props.selectsEnd && a.props.startDate ? a.props.startDate : a.props.selectsStart && a.props.endDate ? a.props.endDate : xt();
      }), vt(bt(a), "calcInitialState", function () {
        var e,
            t = a.getPreSelection(),
            r = vr(a.props),
            n = Dr(a.props),
            o = r && nt.default(t, Ve.default(r)) ? r : n && at.default(t, Je.default(n)) ? n : t;
        return {
          open: a.props.startOpen || !1,
          preventFocus: !1,
          preSelection: null !== (e = a.props.selectsRange ? a.props.startDate : a.props.selected) && void 0 !== e ? e : o,
          highlightDates: wr(a.props.highlightDates),
          focused: !1,
          shouldFocusDayInline: !1
        };
      }), vt(bt(a), "clearPreventFocusTimeout", function () {
        a.preventFocusTimeout && clearTimeout(a.preventFocusTimeout);
      }), vt(bt(a), "setFocus", function () {
        a.input && a.input.focus && a.input.focus({
          preventScroll: !0
        });
      }), vt(bt(a), "setBlur", function () {
        a.input && a.input.blur && a.input.blur(), a.cancelFocusInput();
      }), vt(bt(a), "setOpen", function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        a.setState({
          open: e,
          preSelection: e && a.state.open ? a.state.preSelection : a.calcInitialState().preSelection,
          lastPreSelectChange: Zr
        }, function () {
          e || a.setState(function (e) {
            return {
              focused: !!t && e.focused
            };
          }, function () {
            !t && a.setBlur(), a.setState({
              inputValue: null
            });
          });
        });
      }), vt(bt(a), "inputOk", function () {
        return ue.default(a.state.preSelection);
      }), vt(bt(a), "isCalendarOpen", function () {
        return void 0 === a.props.open ? a.state.open && !a.props.disabled && !a.props.readOnly : a.props.open;
      }), vt(bt(a), "handleFocus", function (e) {
        a.state.preventFocus || (a.props.onFocus(e), a.props.preventOpenOnFocus || a.props.readOnly || a.setOpen(!0)), a.setState({
          focused: !0
        });
      }), vt(bt(a), "cancelFocusInput", function () {
        clearTimeout(a.inputFocusTimeout), a.inputFocusTimeout = null;
      }), vt(bt(a), "deferFocusInput", function () {
        a.cancelFocusInput(), a.inputFocusTimeout = setTimeout(function () {
          return a.setFocus();
        }, 1);
      }), vt(bt(a), "handleDropdownFocus", function () {
        a.cancelFocusInput();
      }), vt(bt(a), "handleBlur", function (e) {
        (!a.state.open || a.props.withPortal || a.props.showTimeInput) && a.props.onBlur(e), a.setState({
          focused: !1
        });
      }), vt(bt(a), "handleCalendarClickOutside", function (e) {
        a.props.inline || a.setOpen(!1), a.props.onClickOutside(e), a.props.withPortal && e.preventDefault();
      }), vt(bt(a), "handleChange", function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];

        var n = t[0];

        if (!a.props.onChangeRaw || (a.props.onChangeRaw.apply(bt(a), t), "function" == typeof n.isDefaultPrevented && !n.isDefaultPrevented())) {
          a.setState({
            inputValue: n.target.value,
            lastPreSelectChange: Xr
          });
          var o = It(n.target.value, a.props.dateFormat, a.props.locale, a.props.strictParsing, a.props.minDate);
          !o && n.target.value || a.setSelected(o, n, !0);
        }
      }), vt(bt(a), "handleSelect", function (e, t, r) {
        if (a.setState({
          preventFocus: !0
        }, function () {
          return a.preventFocusTimeout = setTimeout(function () {
            return a.setState({
              preventFocus: !1
            });
          }, 50), a.preventFocusTimeout;
        }), a.props.onChangeRaw && a.props.onChangeRaw(t), a.setSelected(e, t, !1, r), !a.props.shouldCloseOnSelect || a.props.showTimeSelect) a.setPreSelection(e);else if (!a.props.inline) {
          a.props.selectsRange || a.setOpen(!1);
          var n = a.props,
              o = n.startDate,
              s = n.endDate;
          !o || s || nt.default(e, o) || a.setOpen(!1);
        }
      }), vt(bt(a), "setSelected", function (e, t, r, n) {
        var o = e;

        if (null === o || !rr(o, a.props)) {
          var s = a.props,
              i = s.onChange,
              p = s.selectsRange,
              l = s.startDate,
              d = s.endDate;
          if (!zt(a.props.selected, o) || a.props.allowSameDay || p) if (null !== o && (!a.props.selected || r && (a.props.showTimeSelect || a.props.showTimeSelectOnly || a.props.showTimeInput) || (o = Ft(o, {
            hour: Pe.default(a.props.selected),
            minute: Me.default(a.props.selected),
            second: _e.default(a.props.selected)
          })), a.props.inline || a.setState({
            preSelection: o
          }), a.props.focusSelectedMonth || a.setState({
            monthSelectedIn: n
          })), p) {
            var c = l && !d,
                u = l && d;
            !l && !d ? i([o, null], t) : c && (nt.default(o, l) ? i([o, null], t) : i([l, o], t)), u && i([o, null], t);
          } else i(o, t);
          r || (a.props.onSelect(o, t), a.setState({
            inputValue: null
          }));
        }
      }), vt(bt(a), "setPreSelection", function (e) {
        var t = void 0 !== a.props.minDate,
            r = void 0 !== a.props.maxDate,
            n = !0;

        if (e) {
          var o = Ve.default(e);
          if (t && r) n = Gt(e, a.props.minDate, a.props.maxDate);else if (t) {
            var s = Ve.default(a.props.minDate);
            n = at.default(e, s) || zt(o, s);
          } else if (r) {
            var i = Je.default(a.props.maxDate);
            n = nt.default(e, i) || zt(o, i);
          }
        }

        n && a.setState({
          preSelection: e
        });
      }), vt(bt(a), "handleTimeChange", function (e) {
        var t = Ft(a.props.selected ? a.props.selected : a.getPreSelection(), {
          hour: Pe.default(e),
          minute: Me.default(e)
        });
        a.setState({
          preSelection: t
        }), a.props.onChange(t), a.props.shouldCloseOnSelect && a.setOpen(!1), a.props.showTimeInput && a.setOpen(!0), a.setState({
          inputValue: null
        });
      }), vt(bt(a), "onInputClick", function () {
        a.props.disabled || a.props.readOnly || a.setOpen(!0), a.props.onInputClick();
      }), vt(bt(a), "onInputKeyDown", function (e) {
        a.props.onKeyDown(e);
        var t = e.key;

        if (a.state.open || a.props.inline || a.props.preventOpenOnFocus) {
          if (a.state.open) {
            if ("ArrowDown" === t || "ArrowUp" === t) {
              e.preventDefault();
              var r = a.calendar.componentNode && a.calendar.componentNode.querySelector(".react-datepicker__day[tabindex=\"0\"]");
              return void (r && r.focus({
                preventScroll: !0
              }));
            }

            var n = xt(a.state.preSelection);
            "Enter" === t ? (e.preventDefault(), a.inputOk() && a.state.lastPreSelectChange === Zr ? (a.handleSelect(n, e), !a.props.shouldCloseOnSelect && a.setPreSelection(n)) : a.setOpen(!1)) : "Escape" === t && (e.preventDefault(), a.setOpen(!1)), a.inputOk() || a.props.onInputError({
              code: 1,
              msg: Gr
            });
          }
        } else "ArrowDown" !== t && "ArrowUp" !== t && "Enter" !== t || a.onInputClick();
      }), vt(bt(a), "onDayKeyDown", function (e) {
        a.props.onKeyDown(e);
        var t = e.key,
            r = xt(a.state.preSelection);
        if ("Enter" === t) e.preventDefault(), a.handleSelect(r, e), !a.props.shouldCloseOnSelect && a.setPreSelection(r);else if ("Escape" === t) e.preventDefault(), a.setOpen(!1), a.inputOk() || a.props.onInputError({
          code: 1,
          msg: Gr
        });else if (!a.props.disabledKeyboardNavigation) {
          var n;

          switch (t) {
            case "ArrowLeft":
              n = ke.default(r, 1);
              break;

            case "ArrowRight":
              n = ve.default(r, 1);
              break;

            case "ArrowUp":
              n = be.default(r, 1);
              break;

            case "ArrowDown":
              n = De.default(r, 1);
              break;

            case "PageUp":
              n = Se.default(r, 1);
              break;

            case "PageDown":
              n = we.default(r, 1);
              break;

            case "Home":
              n = Ce.default(r, 1);
              break;

            case "End":
              n = ge.default(r, 1);
          }

          if (!n) return void (a.props.onInputError && a.props.onInputError({
            code: 1,
            msg: Gr
          }));

          if (e.preventDefault(), a.setState({
            lastPreSelectChange: Zr
          }), a.props.adjustDateOnChange && a.setSelected(n), a.setPreSelection(n), a.props.inline) {
            var o = Ye.default(r),
                s = Ye.default(n),
                i = Ie.default(r),
                p = Ie.default(n);
            o !== s || i !== p ? a.setState({
              shouldFocusDayInline: !0
            }) : a.setState({
              shouldFocusDayInline: !1
            });
          }
        }
      }), vt(bt(a), "onPopperKeyDown", function (e) {
        "Escape" === e.key && (e.preventDefault(), a.setState({
          preventFocus: !0
        }, function () {
          a.setOpen(!1), setTimeout(function () {
            a.setFocus(), a.setState({
              preventFocus: !1
            });
          });
        }));
      }), vt(bt(a), "onClearClick", function (e) {
        e && e.preventDefault && e.preventDefault(), a.props.selectsRange ? a.props.onChange([null, null], e) : a.props.onChange(null, e), a.setState({
          inputValue: null
        });
      }), vt(bt(a), "clear", function () {
        a.onClearClick();
      }), vt(bt(a), "onScroll", function (e) {
        "boolean" == typeof a.props.closeOnScroll && a.props.closeOnScroll ? e.target !== document && e.target !== document.documentElement && e.target !== document.body || a.setOpen(!1) : "function" == typeof a.props.closeOnScroll && a.props.closeOnScroll(e) && a.setOpen(!1);
      }), vt(bt(a), "renderCalendar", function () {
        return a.props.inline || a.isCalendarOpen() ? de.default.createElement(zr, {
          ref: function (e) {
            a.calendar = e;
          },
          locale: a.props.locale,
          calendarStartDay: a.props.calendarStartDay,
          chooseDayAriaLabelPrefix: a.props.chooseDayAriaLabelPrefix,
          disabledDayAriaLabelPrefix: a.props.disabledDayAriaLabelPrefix,
          weekAriaLabelPrefix: a.props.weekAriaLabelPrefix,
          adjustDateOnChange: a.props.adjustDateOnChange,
          setOpen: a.setOpen,
          shouldCloseOnSelect: a.props.shouldCloseOnSelect,
          dateFormat: a.props.dateFormatCalendar,
          useWeekdaysShort: a.props.useWeekdaysShort,
          formatWeekDay: a.props.formatWeekDay,
          dropdownMode: a.props.dropdownMode,
          selected: a.props.selected,
          preSelection: a.state.preSelection,
          onSelect: a.handleSelect,
          onWeekSelect: a.props.onWeekSelect,
          openToDate: a.props.openToDate,
          minDate: a.props.minDate,
          maxDate: a.props.maxDate,
          selectsStart: a.props.selectsStart,
          selectsEnd: a.props.selectsEnd,
          selectsRange: a.props.selectsRange,
          startDate: a.props.startDate,
          endDate: a.props.endDate,
          excludeDates: a.props.excludeDates,
          excludeDateIntervals: a.props.excludeDateIntervals,
          filterDate: a.props.filterDate,
          onClickOutside: a.handleCalendarClickOutside,
          formatWeekNumber: a.props.formatWeekNumber,
          highlightDates: a.state.highlightDates,
          includeDates: a.props.includeDates,
          includeDateIntervals: a.props.includeDateIntervals,
          includeTimes: a.props.includeTimes,
          injectTimes: a.props.injectTimes,
          inline: a.props.inline,
          shouldFocusDayInline: a.state.shouldFocusDayInline,
          peekNextMonth: a.props.peekNextMonth,
          showMonthDropdown: a.props.showMonthDropdown,
          showPreviousMonths: a.props.showPreviousMonths,
          useShortMonthInDropdown: a.props.useShortMonthInDropdown,
          showMonthYearDropdown: a.props.showMonthYearDropdown,
          showWeekNumbers: a.props.showWeekNumbers,
          showYearDropdown: a.props.showYearDropdown,
          withPortal: a.props.withPortal,
          forceShowMonthNavigation: a.props.forceShowMonthNavigation,
          showDisabledMonthNavigation: a.props.showDisabledMonthNavigation,
          scrollableYearDropdown: a.props.scrollableYearDropdown,
          scrollableMonthYearDropdown: a.props.scrollableMonthYearDropdown,
          todayButton: a.props.todayButton,
          weekLabel: a.props.weekLabel,
          outsideClickIgnoreClass: $r,
          fixedHeight: a.props.fixedHeight,
          monthsShown: a.props.monthsShown,
          monthSelectedIn: a.state.monthSelectedIn,
          onDropdownFocus: a.handleDropdownFocus,
          onMonthChange: a.props.onMonthChange,
          onYearChange: a.props.onYearChange,
          dayClassName: a.props.dayClassName,
          weekDayClassName: a.props.weekDayClassName,
          monthClassName: a.props.monthClassName,
          timeClassName: a.props.timeClassName,
          showTimeSelect: a.props.showTimeSelect,
          showTimeSelectOnly: a.props.showTimeSelectOnly,
          onTimeChange: a.handleTimeChange,
          timeFormat: a.props.timeFormat,
          timeIntervals: a.props.timeIntervals,
          minTime: a.props.minTime,
          maxTime: a.props.maxTime,
          excludeTimes: a.props.excludeTimes,
          filterTime: a.props.filterTime,
          timeCaption: a.props.timeCaption,
          className: a.props.calendarClassName,
          container: a.props.calendarContainer,
          yearItemNumber: a.props.yearItemNumber,
          yearDropdownItemNumber: a.props.yearDropdownItemNumber,
          previousMonthAriaLabel: a.props.previousMonthAriaLabel,
          previousMonthButtonLabel: a.props.previousMonthButtonLabel,
          nextMonthAriaLabel: a.props.nextMonthAriaLabel,
          nextMonthButtonLabel: a.props.nextMonthButtonLabel,
          previousYearAriaLabel: a.props.previousYearAriaLabel,
          previousYearButtonLabel: a.props.previousYearButtonLabel,
          nextYearAriaLabel: a.props.nextYearAriaLabel,
          nextYearButtonLabel: a.props.nextYearButtonLabel,
          timeInputLabel: a.props.timeInputLabel,
          disabledKeyboardNavigation: a.props.disabledKeyboardNavigation,
          renderCustomHeader: a.props.renderCustomHeader,
          popperProps: a.props.popperProps,
          renderDayContents: a.props.renderDayContents,
          onDayMouseEnter: a.props.onDayMouseEnter,
          onMonthMouseLeave: a.props.onMonthMouseLeave,
          selectsDisabledDaysInRange: a.props.selectsDisabledDaysInRange,
          showTimeInput: a.props.showTimeInput,
          showMonthYearPicker: a.props.showMonthYearPicker,
          showFullMonthYearPicker: a.props.showFullMonthYearPicker,
          showTwoColumnMonthYearPicker: a.props.showTwoColumnMonthYearPicker,
          showFourColumnMonthYearPicker: a.props.showFourColumnMonthYearPicker,
          showYearPicker: a.props.showYearPicker,
          showQuarterYearPicker: a.props.showQuarterYearPicker,
          showPopperArrow: a.props.showPopperArrow,
          excludeScrollbar: a.props.excludeScrollbar,
          handleOnKeyDown: a.props.onKeyDown,
          handleOnDayKeyDown: a.onDayKeyDown,
          isInputFocused: a.state.focused,
          customTimeInput: a.props.customTimeInput,
          setPreSelection: a.setPreSelection
        }, a.props.children) : null;
      }), vt(bt(a), "renderDateInput", function () {
        var e,
            t = ce.default(a.props.className, vt({}, $r, a.state.open)),
            r = a.props.customInput || de.default.createElement("input", {
          type: "text"
        }),
            n = a.props.customInputRef || "ref",
            o = "string" == typeof a.props.value ? a.props.value : "string" == typeof a.state.inputValue ? a.state.inputValue : a.props.selectsRange ? function (e, t, r) {
          if (!e) return "";
          var a = Rt(e, r),
              n = t ? Rt(t, r) : "";
          return "".concat(a, " - ").concat(n);
        }(a.props.startDate, a.props.endDate, a.props) : Rt(a.props.selected, a.props);
        return de.default.cloneElement(r, (vt(e = {}, n, function (e) {
          a.input = e;
        }), vt(e, "value", o), vt(e, "onBlur", a.handleBlur), vt(e, "onChange", a.handleChange), vt(e, "onClick", a.onInputClick), vt(e, "onFocus", a.handleFocus), vt(e, "onKeyDown", a.onInputKeyDown), vt(e, "id", a.props.id), vt(e, "name", a.props.name), vt(e, "autoFocus", a.props.autoFocus), vt(e, "placeholder", a.props.placeholderText), vt(e, "disabled", a.props.disabled), vt(e, "autoComplete", a.props.autoComplete), vt(e, "className", ce.default(r.props.className, t)), vt(e, "title", a.props.title), vt(e, "readOnly", a.props.readOnly), vt(e, "required", a.props.required), vt(e, "tabIndex", a.props.tabIndex), vt(e, "aria-describedby", a.props.ariaDescribedBy), vt(e, "aria-invalid", a.props.ariaInvalid), vt(e, "aria-labelledby", a.props.ariaLabelledBy), vt(e, "aria-required", a.props.ariaRequired), e));
      }), vt(bt(a), "renderClearButton", function () {
        var e = a.props,
            t = e.isClearable,
            r = e.selected,
            n = e.startDate,
            o = e.endDate,
            s = e.clearButtonTitle,
            i = e.clearButtonClassName,
            p = void 0 === i ? "" : i,
            l = e.ariaLabelClose,
            d = void 0 === l ? "Close" : l;
        return !t || null == r && null == n && null == o ? null : de.default.createElement("button", {
          type: "button",
          className: "react-datepicker__close-icon ".concat(p).trim(),
          "aria-label": d,
          onClick: a.onClearClick,
          title: s,
          tabIndex: -1
        });
      }), a.state = a.calcInitialState(), a;
    }

    return yt(r, [{
      key: "componentDidMount",
      value: function () {
        window.addEventListener("scroll", (this || _global).onScroll, !0);
      }
    }, {
      key: "componentDidUpdate",
      value: function (e, t) {
        var r, a;
        e.inline && (r = e.selected, a = (this || _global).props.selected, r && a ? Ye.default(r) !== Ye.default(a) || Ie.default(r) !== Ie.default(a) : r !== a) && this.setPreSelection((this || _global).props.selected), void 0 !== (this || _global).state.monthSelectedIn && e.monthsShown !== (this || _global).props.monthsShown && this.setState({
          monthSelectedIn: 0
        }), e.highlightDates !== (this || _global).props.highlightDates && this.setState({
          highlightDates: wr((this || _global).props.highlightDates)
        }), t.focused || zt(e.selected, (this || _global).props.selected) || this.setState({
          inputValue: null
        }), t.open !== (this || _global).state.open && (!1 === t.open && !0 === (this || _global).state.open && (this || _global).props.onCalendarOpen(), !0 === t.open && !1 === (this || _global).state.open && (this || _global).props.onCalendarClose());
      }
    }, {
      key: "componentWillUnmount",
      value: function () {
        this.clearPreventFocusTimeout(), window.removeEventListener("scroll", (this || _global).onScroll, !0);
      }
    }, {
      key: "renderInputContainer",
      value: function () {
        return de.default.createElement("div", {
          className: "react-datepicker__input-container"
        }, this.renderDateInput(), this.renderClearButton());
      }
    }, {
      key: "render",
      value: function () {
        var e = this.renderCalendar();
        if ((this || _global).props.inline) return e;

        if ((this || _global).props.withPortal) {
          var t = (this || _global).state.open ? de.default.createElement("div", {
            className: "react-datepicker__portal"
          }, e) : null;
          return (this || _global).state.open && (this || _global).props.portalId && (t = de.default.createElement(jr, {
            portalId: (this || _global).props.portalId,
            portalHost: (this || _global).props.portalHost
          }, t)), de.default.createElement("div", null, this.renderInputContainer(), t);
        }

        return de.default.createElement(Ur, {
          className: (this || _global).props.popperClassName,
          wrapperClassName: (this || _global).props.wrapperClassName,
          hidePopper: !this.isCalendarOpen(),
          portalId: (this || _global).props.portalId,
          portalHost: (this || _global).props.portalHost,
          popperModifiers: (this || _global).props.popperModifiers,
          targetComponent: this.renderInputContainer(),
          popperContainer: (this || _global).props.popperContainer,
          popperComponent: e,
          popperPlacement: (this || _global).props.popperPlacement,
          popperProps: (this || _global).props.popperProps,
          popperOnKeyDown: (this || _global).onPopperKeyDown,
          enableTabLoop: (this || _global).props.enableTabLoop
        });
      }
    }], [{
      key: "defaultProps",
      get: function () {
        return {
          allowSameDay: !1,
          dateFormat: "MM/dd/yyyy",
          dateFormatCalendar: "LLLL yyyy",
          onChange: function () {},
          disabled: !1,
          disabledKeyboardNavigation: !1,
          dropdownMode: "scroll",
          onFocus: function () {},
          onBlur: function () {},
          onKeyDown: function () {},
          onInputClick: function () {},
          onSelect: function () {},
          onClickOutside: function () {},
          onMonthChange: function () {},
          onCalendarOpen: function () {},
          onCalendarClose: function () {},
          preventOpenOnFocus: !1,
          onYearChange: function () {},
          onInputError: function () {},
          monthsShown: 1,
          readOnly: !1,
          withPortal: !1,
          selectsDisabledDaysInRange: !1,
          shouldCloseOnSelect: !0,
          showTimeSelect: !1,
          showTimeInput: !1,
          showPreviousMonths: !1,
          showMonthYearPicker: !1,
          showFullMonthYearPicker: !1,
          showTwoColumnMonthYearPicker: !1,
          showFourColumnMonthYearPicker: !1,
          showYearPicker: !1,
          showQuarterYearPicker: !1,
          strictParsing: !1,
          timeIntervals: 30,
          timeCaption: "Time",
          previousMonthAriaLabel: "Previous Month",
          previousMonthButtonLabel: "Previous Month",
          nextMonthAriaLabel: "Next Month",
          nextMonthButtonLabel: "Next Month",
          previousYearAriaLabel: "Previous Year",
          previousYearButtonLabel: "Previous Year",
          nextYearAriaLabel: "Next Year",
          nextYearButtonLabel: "Next Year",
          timeInputLabel: "Time",
          enableTabLoop: !0,
          yearItemNumber: Ot,
          renderDayContents: function (e) {
            return e;
          },
          focusSelectedMonth: !1,
          showPopperArrow: !0,
          excludeScrollbar: !0,
          customTimeInput: null,
          calendarStartDay: void 0
        };
      }
    }]), r;
  }(de.default.Component),
      Xr = "input",
      Zr = "navigate";

  e.CalendarContainer = Br, e.default = Jr, e.getDefaultLocale = Jt, e.registerLocale = function (e, t) {
    var r = "undefined" != typeof window ? window : _global;
    r.__localeData__ || (r.__localeData__ = {}), r.__localeData__[e] = t;
  }, e.setDefaultLocale = function (e) {
    ("undefined" != typeof window ? window : _global).__localeId__ = e;
  }, Object.defineProperty(e, "__esModule", {
    value: !0
  });
});
export default exports;